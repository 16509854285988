// Users.js
import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Header from "../components/Layout/Header/Header";
import { IFOCUS_SUPER_ADMIN, role, roleHandler } from "../constants/Roles";
import { BackwardIcon } from "@heroicons/react/16/solid";
import UserModel from "../components/Users/UserModel";
import UserList from "../components/Users/UserList";
import { v4 as uuidv4 } from "uuid";
import { apiGet, apiPost, apiPut } from "../utils/apiHelper";
import LocalStorage from "../components/StorageUtil/LocalStorage";
import { userMessage } from "../constants/Validation";
const Users = () => {
  const { clientId } = LocalStorage.getResponseData();
  const navigate = useNavigate();
  const [viewTable, setViewTable] = useState(false);
  const location = useLocation();
  const currentURL = location.pathname;
  const [userModal, setUserModal] = useState({
    isOpen: false,
    user: null,
  });
  const [isLoading, setisLoading] = useState(true);
  const [user, setUser] = useState([]);

  useEffect(() => {
    fetchUser();
  }, []);
  const fetchUser = async () => {
    try {
      const response = await apiGet(
        role === IFOCUS_SUPER_ADMIN
          ? userMessage.api.userAll
          : userMessage.api.userClient+`${clientId}`
      );
      if (response.status === 200) {
        setUser(response.data);
        setisLoading(false);
      } else {
        toast.error(userMessage.message.failedToFetch);
        setisLoading(false);
      }
    } catch (error) {
      setisLoading(false);
      toast.error(userMessage.message.failedToFetch);
    }
  };

  const handleModalOpen = (user) => {
    setUserModal({ isOpen: true, user });
  };

  const handleModalClose = () => {
    setUserModal({ isOpen: false, user: null });
  };

  const handleSaveUser = async (values) => {
    try {
      if (userModal.user) {
        await apiPut(
          userMessage.api.userUpdate+`${userModal.user.id}`,
          values
        );
        toast.success(userMessage.message.successUpdate);
      } else {
        await apiPost(userMessage.api.register, values);
        toast.success(userMessage.message.successCreate);
      }
      fetchUser();
      handleModalClose();
    } catch (error) {
      toast.error(userMessage.message.errorFetchData);
    }
  };
  const viewTableHandle = () => {
    setViewTable(!viewTable);
  };
  return (
    <>
      <Header />
      <div className="container mx-auto px-4 py-8">
        {viewTable && (
          <div className="bg-gray-900 min-h-screen">
            <div className="h-screen overflow-y-auto bg-gray-900 mt-2 items-center justify-center">
              <div className="mx-auto  px-4 py-16 sm:px-6 sm:py-6  lg:px-8 custom-bg-color">
                <div className="w-full flex justify-between items-center"></div>
                <div className="flex flex-col sm:flex-row items-center justify-between mt-4">
                  <h3 className="font-medium leading-6 text-white sm:mt-0">
                    {userMessage.message.userDetails}
                  </h3>
                  <BackwardIcon
                    className=" w-8 h-8 text-white hover:text-blue-400"
                    onClick={() => {
                      setViewTable(false);
                    }}
                  />
                </div>
                <div className="overflow-x-auto mt-4">
                  {user.length !== 0 && (
                    <div className="border border-solid border-gray-700 rounded-lg p-4 mb-4">
                      <table className="min-w-full divide-y divide-gray-700">
                        <thead className="bg-gray-800">
                          <tr>
                            {userMessage.tableHeadings.map((heading, index) => (
                              <th
                                key={uuidv4()}
                                className="px-6 py-3 text-left text-xs font-medium text-gray-300 uppercase tracking-wider"
                              >
                                {heading}
                              </th>
                            ))}
                          </tr>
                        </thead>
                        <tbody className="bg-gray-900 divide-y divide-gray-700">
                          {user.map((usr, index) => (
                            <tr key={usr.id}>
                              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-300">
                                {usr.name}
                              </td>
                              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-300">
                                {usr.officialEmail}
                              </td>
                              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-300">
                                {usr.role}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
        <div>
          {!viewTable && (
            <div>
              {/* users Section */}
              <div className="flex justify-between items-center mt-4">
                <div className=" flex">
                  <BackwardIcon
                    className=" w-8 h-8 text-white hover:text-blue-400"
                    onClick={() => navigate(-1)}
                  />
                  <h2 className="text-2xl ml-10 text-[#E6E6E6] font-semibold">
                    {userMessage.message.registeredUsers}
                  </h2>
                </div>
                {currentURL === userMessage.url.user &&
                roleHandler(userMessage.message.adminSuperAdmin) ? (
                  <button
                    className="custom-btn-color text-white px-3 py-1 rounded-sm w-40 h-11"
                    onClick={() => handleModalOpen(null)}
                  >
                    {userMessage.message.addUsers}
                  </button>
                ) : null}
              </div>

              {/* Cards Section */}
              <UserList
                user={user}
                handleEdit={handleModalOpen}
                isLoading={isLoading}
                viewTable={viewTableHandle}
              />

              {/* Add/Update user Modal */}
              {userModal.isOpen && (
                <UserModel
                  onClose={handleModalClose}
                  initialValues={userModal.user || {}}
                  onSubmit={handleSaveUser}
                  user={user}
                />
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Users;
