import React, { useState, useRef, useEffect } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  fileUploadSuccess,
  submit,
  selectMonthOption,
  serverError,
  selectMonth,
  monthDataNotFound,
  roleHandler,
  adminCoordinator,
  uploadReport,
  allowedRole,
  errorUploadFile,
  workerError,
  invalidExcel,
  removeOtherData,
  releaseExcel,
  workerFuntion,
  invalidFile,
  allowedMimeTypes,
  ALLOWED_EXTENSIONS
} from "../../constants/Roles";
import {
  tableLoaderHandler,
  dateConverter,
  monthOptions,
  excelValidate,
  genricExcelTable,
  hasUndefined,
} from "../../utils/dateConverter";
import { v4 as uuidv4 } from "uuid";
import { apiGet, apiPost } from "../../utils/apiHelper";
const MonthlyRelease = ({ userRole, handleFileUploadSuccess }) => {
  const [viewExcelTable, setViewExcelTable] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isTableDataLoading, setTableDataIsLoading] = useState(true);
  const [chooseFile, setChooseFile] = useState(null);
  const [chooseFileName, setChooseFileName] = useState("");
  const [selectedMonth, setSelectedMonth] = useState("");
  const [data, setData] = useState([]);
  const [errors, setErrors] = useState({});
  useEffect(() => {
    setViewExcelTable(excelValidate(errors));
    if (hasUndefined(data)) {
      setViewExcelTable(true);
      toast.error(invalidExcel);
    }
  }, [errors]);

  const fileInputRef = useRef(null);
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      if (!allowedMimeTypes.includes(file.type)) {
        toast.error(invalidFile);
        return;
      }
      const fileExtension = file.name.slice(file.name.lastIndexOf(".")).toLowerCase();
      if (!ALLOWED_EXTENSIONS.includes(fileExtension)) {
        toast.error(invalidFile);
        return;
      }
      setChooseFile(file);
      setChooseFileName(file ? file.name : "");

      const reader = new FileReader();
      const worker = new Worker(workerFuntion);
      reader.onload = async (event) => {
        setLoading(true);
        worker.postMessage({
          type: releaseExcel,
          data: event.target.result,
        });
        worker.onmessage = (e) => {
          const { data, errors } = e.data;
          setData(data);
          setErrors(errors);
          setLoading(false);
        };
        worker.onerror = (error) => {
          console.error(workerError, error);
          toast.error(errorUploadFile);
          setLoading(false);
        };
      };

      reader.readAsArrayBuffer(file);
    }
  };

  useEffect(() => {
    if (selectedMonth !== "") {
      monthWiseData();
    }
  }, [selectedMonth]);

  const monthWiseData = async () => {
    try {
      if (!selectedMonth) {
        !selectedMonth ? toast.error(selectMonth) : toast.error(invalidExcel);
        return;
      } else {
        const response = await apiGet(`/key/release/${selectedMonth}`);
        if (response.status === 200) {
          setData(response.data);
          setTableDataIsLoading(
            Array(response?.data)?.length > 0 ? false : true
          );
        }
      }
    } catch (e) {
      toast.error(serverError);
    }
  };

  const handleSubmit = async () => {
    if (chooseFile && selectedMonth && !viewExcelTable) {
      try {
        const keyReleaseData = data.map((obj) => ({
          ...obj,
          month: selectedMonth,
        }));

        const response = await apiPost(`/key/release`, keyReleaseData);

        if (response.status === 200) {
          toast.success(fileUploadSuccess);
          setChooseFile(null);
          setChooseFileName("");
          handleFileUploadSuccess();
        }
      } catch (error) {
        toast.error(error.data?.response?.message);
      }
    } else {
      viewExcelTable ? toast.error(removeOtherData) : toast.error(selectMonth);
    }
  };

  if (allowedRole.includes(userRole)) {
    return null;
  }

  const releasTableHeadings = [
    "Build Version",
    "Key Features Released",
    "Plateform",
    "Released Date",
  ];

  const readalbeDataView = () => {
    if (!selectedMonth && data?.length === 0) {
      return <h1 className=" text-white text-center">{monthDataNotFound}</h1>;
    }
    return data.length === 0 ? (
      tableLoaderHandler(isTableDataLoading)
    ) : (
      <div className="border border-solid overflow-x-auto border-gray-700 rounded-lg p-4 mb-4">
        <table className=" border-collapse border border-slate-400 min-w-full divide-y divide-gray-700">
          <thead className="bg-gray-800">
            <tr>
              {releasTableHeadings.map((heading, index) => (
                <th
                  key={uuidv4()}
                  className="px-4 h-14 border border-slate-300 text-left text-xs font-medium text-nowrap text-gray-300 uppercase tracking-wider "
                >
                  {heading}
                </th>
              ))}
            </tr>
          </thead>
          <tbody className="bg-gray-900 divide-y divide-gray-900">
            {data.map((row, index) => (
              <tr key={uuidv4()}>
                <td className=" border border-slate-300 px-4 h-14 whitespace-nowrap text-sm text-gray-300">
                  {row.buildVersion}
                </td>
                <td className="border border-slate-300 px-4 h-14 whitespace-nowrap text-sm text-gray-300">
                  {row?.keyFeaturesReleased?.split(/\r?\n/)?.map((line, index) => (
                    <span key={index}>
                      {line}
                      {index <
                        row.keyFeaturesReleased?.split(/\r?\n/).length - 1 && (
                        <br />
                      )}
                    </span>
                  ))}
                </td>
                <td className="border border-slate-300 px-4 h-14 text-ellipsis text-sm text-gray-300">
                  {row.plateform}
                </td>
                <td className="border border-slate-300 px-4 h-14 whitespace-nowrap text-sm text-gray-300">
                  {row.releasedDate !== ""
                    ? dateConverter(parseInt(row.releasedDate))
                    : "--"}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  };
  return (
    <>
      <div className=" mt-4">
        <div className="flex">
          <select
            className=" mr-4 bg-gray-900 border border-gray-700 hover:border-gray-500 px-4 py-2 pr-8 rounded shadow leading-tight text-[#6B778E] focus:outline-none focus:shadow-outline"
            onChange={(e) => setSelectedMonth(e.target.value)}
            required
          >
            <option value="">{selectMonthOption}</option>
            {monthOptions.map((name, index) => (
              <option key={name} value={name}>
                {name}
              </option>
            ))}
          </select>
          {roleHandler(adminCoordinator) && (
            <>
              <input
                type="file"
                accept=".xlsx, .xls"
                ref={fileInputRef}
                onChange={handleFileChange}
                className="hidden"
              />
              {loading && (
                <div className="spinner-border animate-spin inline-block w-8 h-8 border-4 rounded-full border-blue-500 border-t-transparent"></div>
              )}
              <button
                onClick={() => fileInputRef.current.click()}
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
              >
                {chooseFileName ? chooseFileName : uploadReport}
              </button>

              <button
                onClick={handleSubmit}
                className="ml-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
              >
                {submit}
              </button>
            </>
          )}
        </div>
        {viewExcelTable && data.length > 0 && (
          <div className="border mt-4 border-solid overflow-x-auto border-gray-700 rounded-lg p-4 mb-4">
            <table className="min-w-full divide-y divide-gray-700">
              <thead className="bg-gray-800">
                <tr>
                  {data.length > 0 &&
                    Object.keys(data[0]).map((key, index) => (
                      <th
                        className="px-4 h-14 border border-slate-300 text-left text-xs font-medium text-nowrap text-gray-300 uppercase tracking-wider"
                        key={uuidv4()}
                      >
                        {key}
                      </th>
                    ))}
                </tr>
              </thead>
              {genricExcelTable(data, errors)}
            </table>
          </div>
        )}
      </div>
      <div className=" mt-5">{readalbeDataView()}</div>
    </>
  );
};

export default MonthlyRelease;
