import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Header from "../Layout/Header/Header";
import { adminSuperAdmin, roleHandler } from "../../constants/Roles";
import {
  ArrowTrendingUpIcon,
  BanknotesIcon,
  ClipboardIcon,
  NewspaperIcon,
  TvIcon,
} from "@heroicons/react/24/outline";
import { apiGet } from "../../utils/apiHelper";
import { dashboardMessage } from "../../constants/Validation";

const Dashboard = () => {
  const [trends, setTrends] = useState([]);
  const navigate = useNavigate();
  const handleReportClick = () => {
    navigate(dashboardMessage.url.clients);
  };
  useEffect(() => {
    fetchTrends();
  }, []);

  const fetchTrends = async () => {
    try {
      const response = await apiGet(dashboardMessage.api.newstrends);
      setTrends(response.data);
    } catch (error) {
      console.error(dashboardMessage.message.error, error);
    }
  };

  return (
    <div className="bg-gray-900 min-h-screen">
      <Header />
      <div className="px-20 py-6">
        <div className="space-y-8">
          <div className="grid lg:grid-cols-8 grid-cols-1 gap-8 ">
            <div className=" lg:col-span-3 col-span-1 text-field-content border border-[#22314B] rounded-2xl p-6 pr-0">
              <div className="grid grid-cols-3 h-96 space-x-5">
                <div className="col-span-1">
                  <div className="flex space-x-3">
                    <TvIcon className=" w-8 h-8 custom-blue-text" />
                    <span className="text-[#C8DBFF] text-2xl">
                      {dashboardMessage.message.reports}
                    </span>
                  </div>
                  <p className=" text-[#6B778E] mt-5 mb-2.5">
                    {dashboardMessage.message.recentReportForms}
                  </p>

                  <button
                    onClick={handleReportClick}
                    className=" text-[#A4B8DD] whitespace-nowrap border mt-56 border-[#2C4063] px-5 py-2 rounded-full "
                  >
                    {dashboardMessage.message.viewDetails}
                  </button>
                </div>
                <div className="flex justify-center items-end pt-10 translate-y-4 col-span-2">
                  <img
                    src="assets/img/images/Body & Footer.png"
                    alt="Report Image"
                    className="h-56 w-full"
                  />
                </div>
              </div>
            </div>

            <div className=" lg:col-span-5 col-span-1 text-field-content  border border-[#22314B]  rounded-2xl p-6">
              <div className="grid grid-cols-12 space-x-3">
                <div className="col-span-5">
                  <div className="flex space-x-3 ml-8 mt-5">
                    <ClipboardIcon className=" w-8 h-8 custom-blue-text" />
                    <span className="text-[#C8DBFF] text-2xl  lg:whitespace-nowrap">
                      {dashboardMessage.message.customerSatisfactionSurvey}
                    </span>
                  </div>
                  <p className=" text-[#6B778E] mt-5 ml-8">
                    Recent feedbacks <br /> received
                  </p>

                  <Link to={"/css"}>
                    <button className=" text-[#A4B8DD] border mt-52  border-[#2C4063] px-5 ml-5 py-2 rounded-full ">
                      {dashboardMessage.message.viewDetails}
                    </button>
                  </Link>
                </div>
                <div className="col-span-6">
                  <img
                    className="p-2 mt-16 h-60"
                    src="assets/img/images/Survey.png"
                    alt="ifocusLogo"
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="grid lg:grid-cols-5 grid-cols-1 lg:gap-8">
            <div className="col-span-3 text-field-content border border-[#22314B] rounded-2xl p-6">
              <div className="grid grid-cols-2 h-80 gap-7">
                <div className="col-span-1">
                  <div className="flex space-x-3">
                    <NewspaperIcon className=" w-8 h-8 custom-blue-text" />

                    <span className="text-[#C8DBFF] text-2xl ">
                      {dashboardMessage.message.newsLetter}
                    </span>
                  </div>
                  <p className=" text-[#6B778E] mt-5 mb-3">
                    {dashboardMessage.message.newsPara}
                  </p>
                  <Link to={dashboardMessage.url.newsLetter}>
                    <button className=" text-[#A4B8DD] border border-[#2C4063] h-11  mt-32	 px-5 py-2 rounded-full ">
                      {dashboardMessage.message.viewDetails}
                    </button>
                  </Link>
                </div>
                <div>
                  <p className=" text-gray-500">
                    {dashboardMessage.message.latestNews}
                  </p>
                  {dashboardMessage?.newLatter.map((item) => (
                    <div
                      className="max-w-sm rounded overflow-hidden shadow-lg flex justify-between mt-2 gap-2"
                      key={`item-${item}`}
                    >
                      <img
                        className="w-16 h-16 rounded-xl"
                        src={item.image}
                        alt="Newsletter Image"
                      />
                      <div>
                        <div className="font-bold text-base text-white">
                          {item.heading}
                        </div>
                        <p className="text-gray-500 text-xs">
                          {item.paragraph}
                        </p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className=" lg:col-span-2 text-field-content border border-[#22314B] rounded-2xl min-[320px]:max-lg:mt-8">
              <div className="grid grid-cols-2">
                <div className="col-span-1">
                  <div className="flex space-x-3 mt-5 ml-8">
                    <ArrowTrendingUpIcon className=" w-8 h-8 custom-blue-text" />

                    <span className="text-[#C8DBFF] text-2xl">
                      {dashboardMessage.message.newsTrends}
                    </span>
                  </div>
                  <p className=" text-[#6B778E] mt-5 mb-1 ml-8">
                    Recent trending topics <br /> based on interests
                  </p>
                </div>
                <div className="flex-col col-span-1">
                  <h1 className="uppercase text-sm mb-4 text-[#7786A0] mt-5">
                    {dashboardMessage.message.trending}
                  </h1>
                  {trends.map((item) => (
                    <a
                      href={item.url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <hr className="border border-[#202F4A] mt-3 mb-3" />
                      <div className="flex-col">
                        <h3 className="text-white ">{item.name}</h3>
                      </div>
                    </a>
                  ))}

                  <hr className="border border-[#202F4A] mt-3 mb-3" />
                </div>
                {roleHandler(adminSuperAdmin) && (
                  <Link to={dashboardMessage.url.newTrends}>
                    <button className=" text-[#A4B8DD] border mt-20 ml-5  border-[#2C4063] h-11 mb-10	 px-5 py-2 rounded-full ">
                      {dashboardMessage.message.viewDetails}
                    </button>
                  </Link>
                )}
              </div>
            </div>
          </div>
          {roleHandler(adminSuperAdmin) && (
            <div className="col-span-12 text-field-content border border-[#22314B] rounded-2xl p-6">
              <div className="grid grid-cols-2 gap-7">
                <div className="col-span-1">
                  <div className="flex space-x-3">
                    <BanknotesIcon className=" w-8 h-8 custom-blue-text" />
                    <span className="text-[#C8DBFF] text-2xl ">
                      {dashboardMessage.message.manageClient}
                    </span>
                  </div>
                  <p className=" text-[#6B778E] mt-5 mb-3">
                    {dashboardMessage.message.managePara}
                  </p>
                </div>
                <div className="flex justify-end items-center">
                  <Link to={"/menu"}>
                    <button className=" text-[#A4B8DD] border border-[#2C4063] h-11	 px-5 py-2 rounded-full ">
                      {dashboardMessage.message.viewDetails}
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
