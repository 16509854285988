import React, { useEffect, useState } from 'react';
import Header from '../Layout/Header/Header';
import Breadcrumb from '../Layout/Header/Breadcrumb';
import NewsLtrPDFViewer from './NewsLtrPDFViewer';
import NewsLtrUploadBtn from './NewsLtrUploadBtn';
import NewsLtrDownloadBtn from './NewsLtrDownloadBtn';
import { toast } from "react-toastify";
import { apiGet } from '../../utils/apiHelper';
import { errorFetchData, role,viewDetails } from '../../constants/Roles';

const breadcrumbs = [
  { name: 'Home', href: '#' },
  { name: 'Newsletter', current: true },
];

const NewsLetter = () => {
  const [allFiles, setAllFiles] = useState([]);
  const [uploadedFile, setUploadedFile] = useState(null);
  const [pageRefresh, setPageRefresh] = useState(0);

  const handleViewFile = (fileName) => {
    setUploadedFile(fileName);
  };

  const refreshPage = () => {
    setPageRefresh(pageRefresh + 1);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response =await apiGet(`/news-letter/get-all`);

        setAllFiles(response.data);
        if (response.data.length > 0) {
          const latestFile = response.data[response.data.length - 1];
          setUploadedFile(latestFile.fileName);
        }
      } catch (error) {
        toast.error(errorFetchData);
      }
    };

    fetchData();
  }, [pageRefresh]);
  return (
    <div className="max-h-screen">
      <Header />
      <div className="mx-auto max-h-screen max-w-7xl px-4 sm:px-6 lg:px-8 ">
        <div className="flex flex-col sm:flex-row sm:items-center mb-4">
          <Breadcrumb items={breadcrumbs} />
        </div>
        <div className="flex flex-row item-center">
          <div >
            <h3 className="text-3xl font-medium leading-none text-white ">
            Latest updates, news and information about Ifocus to keep up to date with happenings on the organization
            </h3>
          </div>
          <div className="w-1/2 flex justify-end">
            {role === 'IFOCUS_ADM' ? (
              <NewsLtrUploadBtn refreshPage={refreshPage} />
            ) : (
              <NewsLtrDownloadBtn uploadedFileDownload={uploadedFile} />
            )}
          </div>
        </div>
        <div className="flex  justify-between mt-14">
          <ul className="overflow-auto scrollbar absolute max-h-80 xl:h-[50rem] 2xl:h-screen lg:max-h-[23.125rem]  max-w-full">
            {allFiles.map((report) => {
              const fileName = report.fileName
                .split('_')
                .slice(2)
                .join('_')
                .replace('.pdf', '');

              return (
                <li
                  key={report.id}
                  className="text-field-content rounded-md text-white border border-[#293C5D] w-[500px] p-4 mb-5"
                >
                  <div className="flex items-center justify-between">
                    <div className="flex flex-col">
                      <p className="text-[#C7D5EE] max-w-xs break-all font-semibold text-lg">
                        {fileName}
                      </p>
                    </div>
                    <button
                      onClick={() => handleViewFile(report.fileName)}
                      className=" bg-customBlue text-[#A4B8DD] font-normal border border-[#2C4063] py-2 px-4 rounded-full"
                    >
                      {viewDetails}
                    </button>
                  </div>
                </li>
              );
            })}
          </ul>
          <div className="border-2 relative left-[550px] border-[#162032]"></div>

          <NewsLtrPDFViewer uploadedFileView={uploadedFile} />
        </div>
      </div>
    </div>
  );
};

export default NewsLetter;
