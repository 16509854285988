import React, { useEffect } from "react";
import { Route, Routes, Navigate, useLocation } from "react-router-dom";
import LoginForm from "../components/Login/LoginForm";
import Header from "../components/Layout/Header/Header";
import Dashboard from "../components/Dashboard/Dashboard";
import Breadcrumb from "../components/Layout/Header/Breadcrumb";
import WeeklyGraph from "../components/WeeklyReport/WeeklyGraph";
import WeeklyTable from "../components/WeeklyReport/WeeklyTable";
import WeeklyInsights from "../components/WeeklyReport/WeeklyInsights";
import MonthlyReport from "../components/MonthlyReport/MonthlyReport";
import Client from "../pages/Client";
import Project from "../pages/Project";
import CustomerSatisfactionSurvey from "../pages/CustomerSatisfactionSurvey";
import FeedbackForm from "../components/CSS/FeedbackForm";
import SurveyList from "../components/CSS/SurveyList";
import NewsLetter from "../components/NewsLetter/NewsLetter";
import UpdatePassword from "../pages/UpdatePassword";
import Reports from "../components/Reports/Reports";
import ProtectedRoute from "../pages/ProtectedRoute";
import SinglePageError from "../pages/SinglePageError";
import ListOpen from "../components/Dashboard/ListOpen";
import Users from "../pages/Users";
import Question from "../pages/Question";
import ProjectMangerCard from "../components/Users/ProjectMangerCard";
import ReportUploadOption from "../components/Dashboard/ReportUploadOption";
import LocalStorage from "../components/StorageUtil/LocalStorage";
import NewsTrendsManager from "../components/NewsAndTrends/NewsTrendsManager";
const isLoggedIn = LocalStorage.isLoggedIn();
const PrivateRoute = ({ children }) => {
  return isLoggedIn ? children : <Navigate to="/login" />;
};

function AppRoutes() {
  const location = useLocation();
  useEffect(() => {
    const currentUrl = location.pathname;
    const urlInfo = { currentUrl };
    if (!isLoggedIn && currentUrl !== "/login") {
      LocalStorage.urlInfo(urlInfo);
    }
  }, [location.pathname, isLoggedIn]);
  return (
    <Routes>
      <Route path="/login" element={<LoginForm />} />
      <Route path="*" element={<SinglePageError />} />
      <Route path="/" element={<Navigate replace to="/login" />} />
      <Route path="/dashboard" element={<Dashboard />} />
      <Route
        path="/:surveyId/feedback-form"
        element={
          <PrivateRoute>
            <FeedbackForm />
          </PrivateRoute>
        }
      />
      <Route element={<ProtectedRoute />}>
        <Route path="/header" element={<Header />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/breadcrumbs" element={<Breadcrumb />} />
        <Route path="/weeklytable" element={<WeeklyTable />} />
        <Route path="/report" element={<Reports />} />
        <Route path="/insights" element={<WeeklyInsights />} />
        <Route path="/weekly-graph" element={<WeeklyGraph />} />
        <Route path="/header" element={<Header />} />
        <Route path="/monthly-report" element={<MonthlyReport />} />
        <Route path="/clients" element={<Client />} />
        <Route path="/manage-clients" element={<Client />} />
        <Route path="/projects" element={<Project />} />
        <Route path="/manage-projects" element={<Project />} />
        <Route path="/css" element={<CustomerSatisfactionSurvey />} />
        <Route path="/css-list" element={<SurveyList />} />
        <Route path="/reset-password" element={<UpdatePassword />} />
        <Route path="/newsletter" element={<NewsLetter />} />
        <Route path="/menu" element={<ListOpen />} />
        <Route path="/report-menu" element={<ReportUploadOption />} />
        <Route path="/users" element={<Users />} />
        <Route path="/question" element={<Question />} />
        <Route path="/project-manager" element={<ProjectMangerCard />} />
        <Route path="/new-trends" element={<NewsTrendsManager />} />
      </Route>
    </Routes>
  );
}

export default AppRoutes;
