import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Header from "../components/Layout/Header/Header";
import Breadcrumb from "../components/Layout/Header/Breadcrumb";
import CSSList from "../components/CSS/CSSList";
import SurveyRequest from "../components/CSS/SurveyRequest";
import { toast } from "react-toastify";
import { adminSuperAdmin, roleHandler, role } from "../constants/Roles";
import { apiRequest } from "../utils/apiHelper";
import { roleUrl } from "../utils/dateConverter";
import { cssMessage } from "../constants/Validation";
const CustomerSatisfactionSurvey = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [CSS, setCSS] = useState([]);
  const [isCssLoading, setCssIsLoading] = useState(true);
  const [selectedCustomer, setSelectedCustomer] = useState(
    cssMessage.message.all
  );
  const currentDate = new Date(); // Get the current date
  const currentDateString = currentDate.toISOString().slice(0, 10);
  const initialValues = {
    customerRepresentativeName: "",
    customerRepresentativeEmail: "",
    projectName: "",
    clientName: "",
    feedbackPeriodFrom: currentDateString,
    feedbackPeriodTo: currentDateString,
  };
  const breadcrumbItems = [
    { name: cssMessage.message.home, href: "/" },
    { name: cssMessage.message.recentSurveys, current: true },
  ];
  useEffect(() => {
    fetchCSS();
  }, []);

  const fetchCSS = async () => {
    try {
      const cssData = await apiRequest(
        cssMessage.message.get,
        roleUrl[role],
        {},
        {}
      );

      setCSS(
        cssData.data.filter(
          (item) =>
            item.clientName !== null &&
            item.cssStatus !== cssMessage.message.initiated
        )
      );
      setCssIsLoading(Array(cssData.data).length > 0 ? false : true);
    } catch (error) {
      toast.error(cssMessage.message.errorFetchData);
    }
  };

  const handleModalOpen = () => {
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  const handleCustomerSelection = (event) => {
    setSelectedCustomer(event.target.value);
  };
  useEffect(() => {
    toast.success(cssMessage.message.lastFourCss);
  }, [selectedCustomer]);

  return (
    <>
      <Header />
      <div className="container mx-auto px-4 py-8">
        <div className="flex justify-between items-center">
          {/* Breadcrumb */}
          <Breadcrumb items={breadcrumbItems} />
        </div>

        {/* Client Section */}

        <div className="flex justify-between items-center mt-4">
          <h2 className="text-2xl text-[#E6E6E6] font-semibold">
            {cssMessage.message.recentSurveys}
          </h2>
          <div className="flex items-center space-x-2">
            {roleHandler(adminSuperAdmin) ? (
              <div>
                {CSS && CSS.length > 0 && (
                  <select
                    id="location"
                    name="location"
                    className="bg-gray-800 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-white ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    onChange={handleCustomerSelection}
                  >
                    {CSS && CSS.length > 0 ? (
                      [
                        cssMessage.message.all,
                        ...new Set(CSS.map((customer) => customer.clientName)),
                      ].map((name, index) => (
                        <option key={name.id}>{name}</option>
                      ))
                    ) : (
                      <option>{cssMessage.message.selectClient}</option>
                    )}
                  </select>
                )}
              </div>
            ) : null}
            <div className="space-x-2">
              <Link to={cssMessage.url.cssList}>
                <button className="custom-btn-color text-white px-3 py-1 rounded-sm w-40 h-11">
                  {cssMessage.message.cssStatus}
                </button>
              </Link>
              {roleHandler(adminSuperAdmin) ? (
                <button
                  className="custom-btn-color text-white px-3 py-1 rounded-sm w-40 h-11"
                  onClick={() => handleModalOpen()}
                >
                  {cssMessage.message.initiateSurvey}
                </button>
              ) : null}
            </div>
          </div>
        </div>
        {/* Cards Section */}
        <CSSList
          cssForms={CSS}
          selectedCustomer={selectedCustomer}
          isCssLoading={isCssLoading}
        />
        {isModalOpen && (
          <SurveyRequest
            onClose={handleModalClose}
            initialValues={initialValues}
          />
        )}
      </div>
    </>
  );
};

export default CustomerSatisfactionSurvey;
