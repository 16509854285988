import html2canvas from "html2canvas";
import { useEffect } from "react";
import jsPDF from "jspdf";
import GridCardLoader from "../components/Generics/LoaderComponent/GridCardLoader";
import LocalStorage from "../components/StorageUtil/LocalStorage";
import TableLoader from "../components/Generics/LoaderComponent/TableLoader";
import {
  success,
  fail,
  downloadNotPerform,
  notFile,
  emptyDate,
  friday,
  emptyCell,
  numberType,
  stringType,
} from "../constants/Roles";
import { v4 as uuidv4 } from "uuid";
import { toast } from "react-toastify";
const { id, clientId } = LocalStorage.getResponseData();
export const excelDateToJSDate = (serial) => {
  if (serial === null) {
    return emptyDate;
  } else {
    const utc_days = Math.floor(serial - 25569);
    const utc_value = utc_days * 86400;
    const date_info = utc_value * 1000;
    return dateFormat(date_info);
  }
};

export const dateFormat = (date_info) => {
  const date = new Date(date_info);
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-indexed
  const year = date.getFullYear();
  return `${day}/${month}/${year}`;
};
export const dayNames = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];
export function getCurrentWeek() {
  const today = new Date();
  const startOfYear = new Date(today.getFullYear(), 0, 0);
  const diff = today - startOfYear;
  const oneWeek = 1000 * 60 * 60 * 24 * 7;
  const day = today.getDay();
  if (dayNames[day] === friday) {
    const currentWeekNumber = Math.floor(diff / oneWeek) + 1;
    return `Week${currentWeekNumber}`;
  } else {
    const currentWeekNumber = Math.floor(diff / oneWeek);
    return `Week${currentWeekNumber}`;
  }
}

export const weekOptions = [];
for (let i = 1; i <= 52; i++) {
  weekOptions.push(`Week${i}`);
}

export const { clientName } = LocalStorage.getClientName();

export const { projectName } = LocalStorage.getProjectName();

export const handleDownloadPdf = async (
  conponentPDF,
  selectedWeek,
  tableData
) => {
  if (tableData.length <= 0) {
    toast.error(downloadNotPerform);
  } else {
    const table = conponentPDF.current;
    const canvas = await html2canvas(table);
    const imgData = canvas.toDataURL("image/png");

    const doc = new jsPDF("p", "mm", "a4");
    const imgProps = doc.getImageProperties(imgData);
    const pdfWidth = doc.internal.pageSize.getWidth();
    const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;

    doc.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight);
    doc.save(selectedWeek + ".pdf");
  }
};

export const cardLoading = (isLoading) => {
  if (isLoading) {
    return <GridCardLoader />;
  } else {
    return <div className="text-white text-center mt-8">No data found.</div>;
  }
};

export const tableLoaderHandler = (isLoading) => {
  if (isLoading) {
    return <TableLoader />;
  } else {
    return <div className="text-white text-center">No data found</div>;
  }
};
export const monthOptions = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "June",
  "July",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];
export const dateConverter = (date) => {
  if (date === null) {
    return "";
  }
  if (typeof date === "string") {
    if (/[/-]/.test(date) && date.length <= 10) {
      return date;
    }
    if (date.length > 10) {
      return date.slice(0, 10);
    }
  }
  return excelDateToJSDate(date);
};

export const useClickOutside = (ref, callback) => {
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        callback();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref, callback]);
};
export const currentURL = (location) => {
  return location.pathname;
};

export const isCurrentPath = (location, path) => {
  return currentURL(location) === path;
};

export const checkStructure = (obj) => {
  const keys = Object.keys(obj);
  if (keys.length === 0) {
    return success;
  }

  for (const key of keys) {
    const nestedObj = obj[key];

    if (typeof nestedObj !== "object" || nestedObj === null) {
      return fail;
    }
    if (Object.keys(nestedObj).length !== 0) {
      return fail;
    }
  }

  return success;
};

export const excelValidate = (errors) => {
  const status = checkStructure(errors);
  return status === fail ? true : false;
};
export const isFieldEmpty = (value) => {
  return value === "" || value === undefined;
};
const renderDetails = (details) => {
  if (typeof details === stringType) {
    return details?.split("\n").map((line, index) => (
      <span key={index}>
        {line}
        {index < details?.split("\n").length - 1 && <br />}
      </span>
    ));
  } else if (typeof details === numberType) {
    return String(details);
  } else {
    return emptyCell;
  }
};

export const genricExcelTable = (data, errors) => {
  return (
    <tbody className="bg-gray-900 divide-y divide-gray-900">
      {data.map((row, rowIndex) => (
        <tr key={uuidv4}>
          {Object.values(row).map((value, cellIndex) => (
            <td
              key={uuidv4()}
              className={`${
                errors[rowIndex]?.[Object.keys(row)[cellIndex]]
                  ? "text-red-500"
                  : "px-4 h-14 whitespace-nowrap text-sm text-gray-300 border border-slate-300"
              }`}
            >
              {renderDetails(value)}
              {errors[rowIndex]?.[Object.keys(row)[cellIndex]] && (
                <span className=" text-red-600">
                  {errors[rowIndex][Object.keys(row)[cellIndex]]}
                </span>
              )}
            </td>
          ))}
        </tr>
      ))}
    </tbody>
  );
};

export const roleUrl = {
  IFOCUS_ADM: `/api/css/client-manager/${clientId}`,
  CUSTOMER_MANAGER: `/api/css/client-manager/${clientId}`,
  PROJECT_MANAGER: `/api/css/customer-manager/${id}`,
  IFOCUS_SUPER_ADMIN: `/api/css`,
};
export const handleFile = (reader, file) => {
  if (file instanceof Blob) {
    return reader.readAsBinaryString(file);
  } else {
    console.error(notFile);
  }
};
export const hasUndefined = (data) =>
  data.some((obj) => {
    return Object.values(obj).some((value) => value === undefined);
  });
