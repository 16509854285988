export const surveyRequest = {
  message: {
    questionField: "Question*",
    select: "Select",
    initiateSurvey: "Initiate Survey",
    enterDetail: "Enter Details",
    customerRepresentativeName: "Customer Representative Name*",
    customerRepresentativeEmail: "Customer Representative Email*",
    clientName: "Client Name*",
    feedbackPeriod: "Feedback Period",
    projectName: "Project Name*",
    cancel: "Cancel",
    initiate: "Initiate",
    error: "Failed to fetch data",
    failedInitiateCss: "Fail initiating CSS",
    cssInitiated: "CSS Initiated Successfully",
    emptyValue: "Please fill current value first!",
    lastValue: "Last Field Can't Deleted !",
  },
  validation: {
    customer: {
      required: "Customer representative name is required",
    },
    email: {
      invalid: "Invalid email address",
      required: "Customer representative email is required",
    },
    project: {
      required: "Project name is required",
    },
    feedback: {
      period: "Feedback period cannot be in the future",
      startDate: "Feedback period start date is required",
      periodFrom: "feedbackPeriodFrom",
      endDate: "Feedback period end date must be after start date",
      endDateRequired: "Feedback period end date is required",
    },
  },
  api: {
    projectManager: "/user/by/customer/project/manager",
    clients: `/clients`,
    projects: "/projects/projects/",
    questionAttributes: "/api/question-attributes",
    initiate: "/api/css/initiate",
    apiCss: "/api/css/",
    question: "/question",
  },
};
export const clientModel = {
  message: {
    questionField: "Question*",
    select: "Select",
    clientDetails: "Client Details",
    pocDetailForClient: "Point of contact (POC) details for client",
    clientName: "Client name*",
    location: "Location*",
    pocName: "POC name*",
    pocContactNumber: "POC contact number*",
    pocEmailAddress: "POC Email address*",
    accountManagerName: "iFocus account manager name*",
    clientStatusName: "Client Status*",
    remarks: "Remarks",
    cancel: "Cancel",
    save: "Save",
  },
  clientStatus: {
    select: "Select Client Status",
    active: "ACTIVE",
    inActive: "INACTIVE",
    closedOption: "CLOSED",
  },
  validation: {
    client: {
      required: "Client name is required",
      nameExits: "Client name already exists",
    },
    location: {
      required: "Location is required",
      mustContain: "Location must contain only alphabets",
    },
    pocContact: {
      required: "POC contact number is required",
      invalid: "Invalid POC contact number",
    },
    pocEmail: {
      required: "POC Email address is required",
      invalid: "Invalid POC Email address",
    },
    accountManager: {
      required: "iFocus account manager name is required",
      must: "Account manager must contain only alphabets",
    },
    projectType: {
      required: "Project type is required",
      remarkLength: "The maximum length of the Remarks field is 100 characters",
      active: "ACTIVE",
    },
    mobileNumber: {
      must: "Mobile number must start with 7 or 8 in Singapore",
      start: "Mobile number cannot start with 9 in Singapore",
    },
  },
};

export const feedbackForm = {
  message: {
    failedToFetch: "Failed to fetch data.",
    dataSaveSuccess: "Data saved successfully.",
    emptyValue: "Please fill current value first",
    saved: "saved",
    faliedToSave: "Failed to save data.",
    errorSaved: "An error occurred while saving data.",
    dataUpdateSuccess: "Data updated successfully.",
    updated: "updated",
    failedToUpdate: "Failed to update data.",
    allFieldMust: "All fields must be filled.",
    startDate: "Start Date",
    endDate: "End Date",

    lastValue: "Last Field Can't Deleted",
    csiUpdate: "CSI Action Updates successfully",
    actionNotPerform: "Action Not perform yet",
    errorFetchData: "Error fetching data:",
    requiredField: "This field is required",
    thankYouFeedback: "Thank you for giving feedback!",
    encounteredSubmitForm:
      "We encountered a problem while trying to submit your form. Please double-check your entries for any errors and try submitting again.",
    css: "Customer Satisfaction Survey",
    kindlyRate: "*Kindly rate our performance for all 7 attributes",
    customerRep: "Customer representative",
    projectName: "Project name",
    from: "From",
    to: "To",
    actionToImprove: "Actions to improve CSI/Sustain 100% CSI",
    cancel: "Cancel",
    submitSurvey: "Submit Survey",
    update: "Update",
    save: "Save",
  },
  validation: {
    cssAction: {
      required: "Comment is required",
      startDateRequired: "Start Date is required",
      startDate: "startDate",
      endDateMust: "End Date must be after Start Date",
      endDateRequired: "End Date is required",
      atLeast: "At least one action is required",
    },
  },
  api:{
    deleteAction:"/csiimprove/"
  }
};

export const testing = {
  message: {
    dateFormet: "Date Formet testing",
    date: "02/07/2024",
    converDate: "2024-7-2",
    converWeek: "current week testing",
    tableLoaderTest: "tableLoaderHandler",
    noDataFoundRender: 'renders "No data found" when isLoading is false',
    noDataFoundDot: "No data found.",
    noDataFound: "No data found",
    cardLoaderTest: "cardLoaderHandler",
    newRender: "newLetter render",
    friday: "Friday",
  },
};
export const projectMessage = {
  message: {
    mouseDown: "mousedown",
    meWatch: "MeWatch",
    reportNotUploaded: "Report not uploaded",
    navProjectName: "navProjectName",
    setProjectName: "SET_PROJECT_NAME",
    active: "active",
    inActive: "inactive",
    closed: "Closed",
    noDataFound: "No data found",
    cardLoaderTest: "cardLoaderHandler",
    newRender: "newLetter render",
    friday: "Friday",
  },
  url: {
    projects: "/projects",
    report: "/report",
    manageProjects: "/manage-projects",
  },
};
export const dashboardMessage = {
  message: {
    error: "Error fetching trends",
    reports: "Reports",
    recentReportForms: "Recent report forms",
    viewDetails: "View details",
    customerSatisfactionSurvey: "Customer Satisfaction Survey",
    newsLetter: "Newsletter",
    newsPara:
      "Latest updates, news and information about Ifocus to keep up to date with happenings on the organization",
    latestNews: "Latest News",
    newsTrends: "News & Trends",
    trending: "trending",
    manageClient: "Manage clients, projects, users and questions",
    managePara:
      "This section allows you to efficiently handle clients,projects ,users and questions by providing comprehensive functionalities for creation and updation.",
  },
  url: {
    clients: "/clients",
    report: "/report",
    newsLetter: "/newsletter",
    newTrends: "/new-trends",
  },
  api: {
    newstrends: "news/trends",
  },
  newLatter: [
    {
      image: "assets/img/images/Image.png",
      heading: "Enterprise Design",
      paragraph:
        " Get the latest news and updates delivered straight to your inbox.",
    },
    {
      image: "https://picsum.photos/id/1/400/300",
      heading: "Best Gold Price",
      paragraph:
        " Get the latest news and updates delivered straight to your inbox.",
    },
    {
      image: "assets/img/images/Image.png",
      heading: "Best React NAtive App",
      paragraph:
        " Get the latest news and updates delivered straight to your inbox.",
    },
    {
      image: "https://picsum.photos/id/1/400/300",
      heading: "Weather Information",
      paragraph:
        " Get the latest news and updates delivered straight to your inbox.",
    },
  ],
};
export const newsTrendsMessage = {
  message: {
    error: "Error fetching trends",
    requiredFields: "Both fields are required!",
    manageNewsTrends: "Manage News Trends",
    update: "Update",
    add: "Add",
    Back: "Back",
    loading: "Loading...",
    name: "Name",
    url: "URL",
    actions: "Actions",
    edit: "Edit",
    delete: "Delete",
  },
  api: {
    newstrends: "news/trends",
    updateNewsTrends: "/news/trends/update/",
    deleteNewsTrends: "/news/trends/delete/",
  },
};
export const projectModelMessage = {
  message: {
    error: "Error fetching trends",
    customerManagerNotFound: " Customer Manager Not Found!!!",
    customerManagerFetch: " Customer Manager Fetch Successful!!",
    projectManagerSuccess: " Project Manager Fetch Successful!!",
    successCreate: "Successfully Created",
    successUpdated: "Successfully Updated",
    projectDetails: "Project Details",
    projectName: "Project Name*",
    location: "Location*",
    projectType: "Project Type*",
    otherType: "Other Type*",
    customerManager: "Customer Manager*",
    projectStartDate: "Project Start Date*",
    projectEndDate: "Project End Date*",
    projectStatus: "Project Status",
    active: "ACTIVE",
    inactive: "INACTIVE",
    closedCap: "CLOSED",
    remarks: "Remarks",
    cancel: "Cancel",
    save: "Save",
    remarkRquired: "Remarks is required",
    remarkLength: "The maximum length of the Remarks field is 100 characters",
    projectNameRequired: "Project name is required",
    projectNameExit: "Project name already exists",
    locationRequired: "Location is required",
    projectPocName: "Project POC Name*",
    locationMust: "Location must contain only alphabets",
    projectPocRequired: "Project POC name is required",
    projectPocMust: "Project POC name must contain only alphabets",
    invalidProjectPocEmail: "Invalid Project POC email address ",
    projectPocEmailRequired: "Project POC email address is required",
    invalidPOCEmail: "Invalid POC Email address",
    dateRequired: "Date is required",
    projectTypeRequired: "Project Type is required",
    projectTech: "Project Technologies is required",
    projectPocEmail: "Project POC Email address*",
    dateFormat: "YYYY-MM-DD",
    emptyValue: "Please fill current value first!",
    lastValue: "Last Field Can't Deleted !",
    select: "Select",
  },
  api: {
    customerManager: "/user/by/CUSTOMER_MANAGER",
    projectManager: "/user/project/manager/",
    updateBulk: "/projects/update/bulk",
    addBulk: "/projects/add/bulk",
  },
};

export const userListMessage = {
  message: {
    error: "Error fetching data",
    customerManagerNotFound: "No customer manager found",
  },
  api: {
    projectManager: "/user/project/manager/",
  },
};
export const userModelMessage = {
  message: {
    error: "Error fetching data",
    errorSave: "Error saving data",
    customerManagerName: "Customer Manager Name*",
    invalidUserEmail: "Invalid User email address ",
    notePass: "NOTE : By default Password is P@ssw0rd@123",
    officalEmail: "Official Email*",
    roles: "Role*",
    save: "Save",
    select: "Select",
    cancel: "Cancel",
    successCreate: "Successfully Created",
    successUpdate: "Successfully Updated",
    userDetails: "Users Details",
    userEmailRequired: "User email address is required",
    userName: "User name*",
    userNameMust: "User Name must contain only alphabets",
    userNameRequired: "User Name is required",
    clientId: "Client Id",
  },
  api: {
    clients: "/clients",
    userUpdate: "/user/update/",
    register: "/register",
  },
};
export const clientMessage = {
  message: {
    error: "Error fetching data",
    successCreate: "Successfully Created",
    successUpdate: "Successfully Updated",
    clientDetails: "Client Details",
    addCient: "Add Client +",
  },
  api: {
    clients: "/clients",
    clientId: "/clients/",
  },
  url: {
    manageClient: "/manage-clients",
  },
  clientTableHeadings: [
    "CLIENT NAME",
    "LOCATION",
    "POC EMAIL ADDRESS",
    "POC NAME",
    "POC CONTACT NUMBER",
    "ACCOUNT Manager",
    "REMARKS",
    "PROJECT COUNT",
    "CLIENT STATUS",
  ],
};
export const cssMessage = {
  message: {
    all: "All",
    cssStatus: "CSS Status",
    errorFetchData: "Error fetching data:",
    initiateSurvey: "Initiate Survey",
    lastFourCss: "Recent Four Surveys",
    recentSurveys: "Recent Surveys",
    selectClient: "Select Client",
    home: "Home",
    get: "GET",
  },
  url: {
    cssList: "/css-list",
  },
};
export const projecPagetMessage = {
  message: {
    active: "ACTIVE",
    adminSuperAdmin: "adminSuperAdmin",
    errorFetchData: "Error fetching data:",
    errorSave: "Error saving data",
    failedToFetch: "Failed to fetch data. Please try again later.",
    successCreate: "Successfully Created",
    successUpdate: "Successfully Updated",
    home: "Home",
    clients: "Clients",
    project: "Projects",
    projectStatus: "projectStatus",
    projectDetails: "Project Details",
  },
  url: {
    cssList: "/css-list",
    manageClient: "/manage-clients",
    manageProject: "/manage-projects",
    clients: "/clients",
  },
  api: {
    projectClientId: "/projects/projects/",
    project: "/projects",
    projectUpdate: "/projects/update/",
  },
  tableHeadings: [
    "PROJECT NAME",
    "LOCATION",
    "PROJECT POC EMAIL ADDRESS",
    "PROJECT POC NAME",
    "PROJECT TECHNOLOGIES",
    "PROJECT TYPE",
    "REMARKS",
    "START DATE",
    "END DATE",
    "PROJECT STATUS",
  ],
};
export const userMessage = {
  message: {
    addUsers: "Add User +",
    adminSuperAdmin: "adminSuperAdmin",
    errorFetchData: "Error fetching data:",
    failedToFetch: "Failed to fetch data. Please try again later.",
    registeredUsers: "Registered Users",
    successCreate: "Successfully Created",
    successUpdate: "Successfully Updated",
    userDetails: "Users Details",
  },
  api: {
    userAll: "/user/all",
    userClient: "user/client/",
    userUpdate: "/users/update/",
    register: "/register",
  },
  url: {
    user: "/users",
  },
  tableHeadings: ["NAME", "EMAIL", "ROLE"],
};
export const monthlyReportMessage = {
  message: {
    home: "Home",
    clients: "Clients",
    project: "Projects",
    reports: "Reports",
    monthlyReport: "Monthly Report",
    error: "Error fetching data",
    meWatchReport: "MeWatch Report",
    keyRelease: "Key Release",
    viewDetails: "View details",
    adminCoordinator: "adminCoordinator",
  },
  url: {
    dashboard: "/dashboard",
    clients: "/clients",
    projects: "/projects",
    report: "/report",
    monthlyReport: "/monthly-report",
  },
  api: {
    allReport: "/report/get-all",
  },
};
