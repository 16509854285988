import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage, FieldArray } from "formik";
import { toast } from "react-toastify";
import * as Yup from "yup";
import Header from "../Layout/Header/Header";
import LocalStorage from "../StorageUtil/LocalStorage";
import Breadcrumb from "../Layout/Header/Breadcrumb";
import {
  IFOCUS_ADM,
  startDate,
  endDate,
  actionNotPerform,
  errorFetchData,
  requiredField,
  thankYouFeedback,
  encounteredSubmitForm,
  css,
  kindlyRate,
  customerRep,
  projectName,
  from,
  to,
  actionToImprove,
  cancel,
  submitSurvey,
  update,
  save,
  roleHandler,
  adminSuperAdmin,
  customerManagerProjectManager,
  IFOCUS_SUPER_ADMIN,
} from "../../constants/Roles";
import {
  MinusCircleIcon,
  PencilIcon,
  PlusCircleIcon,
  TrashIcon,
} from "@heroicons/react/24/outline";
import { apiDelete, apiRequest } from "../../utils/apiHelper";
import { feedbackForm } from "../../constants/Validation";
const createDefaultData = (surveyId, officialEmail, formattedDate) => [
  {
    surveyId,
    comment: "",
    startDate: "",
    endDate: "",
    modifiedBy: "",
    modifiedDateTime: "",
    addedBy: officialEmail,
    addedDateTime: formattedDate,
    edit: false,
    seqNo: 1,
  },
];

const FeedbackForm = () => {
  const [questions, setQuestions] = useState([]);
  const [initialValues, setInitialValues] = useState(null);
  const [surveyData, setSurveyData] = useState(null);
  const [actionItemAdd, setActionItemAdd] = useState(false);

  const navigate = useNavigate();
  let { surveyId } = useParams();
  const { role, officialEmail } = LocalStorage.getResponseData();
  const currentDate = new Date();
  const formattedDate =
    currentDate.toLocaleDateString() + " " + currentDate.toLocaleTimeString();

  const [initialValuesAgain, setInitialValuesAgain] = useState(
    createDefaultData(surveyId, officialEmail, formattedDate)
  );
  useEffect(() => {
    handleCsiResponseChange();
  }, []);

  const validationSchemaAgain = Yup.object({
    cssAction: Yup.array()
      .of(
        Yup.object({
          comment: Yup.string().required(
            feedbackForm.validation.cssAction.required
          ),
          startDate: Yup.date().required(
            feedbackForm.validation.cssAction.startDateRequired
          ),
          endDate: Yup.date()
            .min(
              Yup.ref(feedbackForm.validation.cssAction.startDate),
              feedbackForm.validation.cssAction.endDate
            )
            .required(feedbackForm.validation.cssAction.endDateRequired),
        })
      )
      .min(1, feedbackForm.validation.cssAction.atLeast),
  });

  const [formStatus, setFormStatus] = useState("");

  const handleCsiResponseChange = async () => {
    try {
      const response = await apiRequest("GET", `/csiimprove/all/${surveyId}`);
      if (response.status === 200) {
        if (response.data.length > 0) {
          const sortedData = sortDataBySeqNo(response.data);
          setInitialValuesAgain({ cssAction: sortedData });
        } else {
          setActionItemAdd(true)
          setInitialValuesAgain({
            cssAction: createDefaultData(
              surveyId,
              officialEmail,
              formattedDate
            ),
          });
        }
      }
    } catch (error) {
      toast.error(feedbackForm.message.failedToFetch);
    }
  };

  const sortDataBySeqNo = (data) => {
    return [...data].sort((a, b) => a.seqNo - b.seqNo);
  };
  
  const handleDeleteActionItem = async (id) => {
    try {
      await apiDelete(feedbackForm.api.deleteAction+`${id}`);
      handleCsiResponseChange();
    } catch (error) {
      console.error(feedbackForm.message.error, error);
    }
  };

  const handleSaveCsi = async (values) => {
    try {
      const response = await apiRequest(
        "POST",
        `/csiimprove/add`,
        values.cssAction
      );
      if (response.status === 200) {
        toast.success(feedbackForm.message.dataSaveSuccess);
        setFormStatus(feedbackForm.message.saved);
      } else {
        toast.error(feedbackForm.message.faliedToSave);
      }
    } catch (error) {
      toast.error(feedbackForm.message.errorSaved);
    }
  };

  const handleUpdateCsi = async (values) => {
    try {
      const response = await apiRequest(
        "PUT",
        `/csiimprove/update`,
       values.cssAction
      );
      if (response.status === 200) {
        toast.success(feedbackForm.message.dataUpdateSuccess);
        setFormStatus(feedbackForm.message.updated);
      } else {
        toast.error(feedbackForm.message.failedToUpdate);
      }
    } catch (error) {
      toast.error(feedbackForm.message.errorSaved);
    }
  };

  const handleFormSubmit = async (values) => {
    const hasEmptyFields = values.cssAction.some(
      (action) => !action.comment || !action.startDate || !action.endDate
    );

    if (hasEmptyFields) {
      toast.error(feedbackForm.message.allFieldMust);
    } else {
      if (actionItemAdd) {
        await handleSaveCsi(values);
      } else {
        await handleUpdateCsi(values);
      }
    }
  };
  const updateSeqNo = (actions) => {
    return actions.map((action, index) => ({
      ...action,
      seqNo: index + 1,
    }));
  };

  function dynamicForm() {
    return (
      <Formik
        initialValues={initialValuesAgain}
        validationSchema={validationSchemaAgain}
        onSubmit={(values, { setSubmitting }) => {
          handleFormSubmit(values).finally(() => setSubmitting(false));
        }}
      >
        {({ values, handleSubmit, isSubmitting, setFieldValue }) => (
          <div>
            <Form onSubmit={handleSubmit}>
              <FieldArray name="cssAction">
                {({ insert, remove }) => (
                  <>
                    {values.cssAction.map((css, index) => (
                      <div key={index} className="border mt-2 p-4 rounded-md">
                        <div className="form-group">
                          <Field
                            as="textarea"
                            name={`cssAction[${index}].comment`}
                            placeholder="Write Action"
                            className="w-full px-3 py-2 border border-[#314466] rounded-md focus:outline-none focus:border-blue-500 bg-transparent text-white"
                          />
                          <ErrorMessage
                            name={`cssAction[${index}].comment`}
                            component="div"
                            className="text-red-500 text-sm mt-1"
                          />
                        </div>
                        <div className="grid grid-cols-4 gap-4 mt-4">
                          <div className="flex">
                            <label
                              htmlFor={`startDate-${index}`}
                              className="block custom-blue-text mb-1 whitespace-nowrap"
                            >
                              {feedbackForm.message.startDate}
                            </label>
                            <Field
                              type="date"
                              name={`cssAction[${index}].startDate`}
                              className="border border-[#1D2A42] rounded-md px-3 py-2 w-44 h-8 custom-blue-text bg-transparent mr-5"
                            />
                            <ErrorMessage
                              name={`cssAction[${index}].startDate`}
                              component="div"
                              className="text-red-500 text-sm mt-1"
                            />
                          </div>
                          <div className="flex">
                            <label
                              htmlFor={`endDate-${index}`}
                              className="block custom-blue-text mb-1 whitespace-nowrap"
                            >
                              {feedbackForm.message.endDate}
                            </label>
                            <Field
                              type="date"
                              name={`cssAction[${index}].endDate`}
                              className="border border-[#1D2A42] rounded-md px-3 py-2 w-44 h-8 custom-blue-text bg-transparent mr-5"
                            />
                            <ErrorMessage
                              name={`cssAction[${index}].endDate`}
                              component="div"
                              className="text-red-500 text-sm mt-1"
                            />
                          </div>
                          <div className="flex col-start-4 col-end-5 mt-4">
                            <PlusCircleIcon
                              onClick={() => {
                                const updatedActions = updateSeqNo([
                                  ...values.cssAction.slice(0, index + 1),
                                  createDefaultData(
                                    surveyId,
                                    officialEmail,
                                    formattedDate
                                  )[0],
                                  ...values.cssAction.slice(index + 1),
                                ]);
                                setFieldValue("cssAction", updatedActions);
                                setActionItemAdd(true);
                              }}
                              className="w-8 h-8 text-white hover:text-blue-600"
                            />
                            
                            {values.cssAction.length > 1 && (
                              <>
                                <MinusCircleIcon
                                  onClick={() => {
                                    if (values.cssAction.length <= 1) {
                                      toast.error(
                                        feedbackForm.message.lastValue
                                      );
                                    } else {
                                      const updatedActions = updateSeqNo([
                                        ...values.cssAction.slice(0, index),
                                        ...values.cssAction.slice(index + 1),
                                      ]);
                                      setFieldValue(
                                        "cssAction",
                                        updatedActions
                                      );
                                      setActionItemAdd(false);
                                    }
                                  }}
                                  className="w-8 h-8 text-white ml-8 hover:text-red-800"
                                />
                                
                              </>
                            )}
                            <PencilIcon
                                  onClick={() => {
                                    const updatedActions = values.cssAction.map(
                                      (action, i) => ({
                                        ...action,
                                        edit:
                                          i === index
                                            ? !action.edit
                                            : action.edit,
                                      })
                                    );
                                    setFieldValue(
                                      "cssAction",
                                      updateSeqNo(updatedActions)
                                    );
                                  }}
                                  className={`w-8 h-8 ${
                                    css.edit ? "text-green-500" : "text-white"
                                  } ml-8 hover:text-red-800`}
                                />
                          </div>
                        </div>
                      </div>
                    ))}
                  </>
                )}
              </FieldArray>
            </Form>
            {!isSubmitting &&
              formStatus !== "submitted" &&
              role === IFOCUS_ADM && (
                <div className="flex justify-end gap-5">
                  <button
                    type="button"
                    onClick={() => handleCancel()}
                    className="bg-transparent border custom-border w-40 lg:w-48 text-white py-2 px-4 rounded-md mt-4 lg:w-1/6 lg:h-12"
                  >
                    {cancel}
                  </button>
                  <button
                    type="button"
                    onClick={() => handleSubmit()}
                    className="bg-blue-500  custom-btn-color text-white py-2 px-4 rounded-md mt-4 w-44 h-12  border border-gray-300 p-3 lg:w-44 lg:h-12 lg:p-3"
                  >
                    {actionItemAdd ? save : update}
                  </button>
                </div>
              )}
          </div>
        )}
      </Formik>
    );
  }

  function dynamicView() {
    return (
      <div>
        {initialValuesAgain.cssAction?.length > 0 ? (
          initialValuesAgain.cssAction?.map((css, index) => (
            <div key={css.seqNo}>
              <div className="mt-4">
                <p className="text-[#C8DBFF] break-words">
                  {`${index + 1}.` + " " + `${css.comment}`}
                </p>
              </div>
              <div className="grid grid-cols-4 gap-4">
                <div className=" flex">
                  <label
                    htmlFor="feedbackPeriodFrom"
                    className="block custom-blue-text mb-1 whitespace-nowrap mt-4"
                  >
                    {startDate}
                  </label>
                  <p className="text-[#C8DBFF] mt-4 ml-4">{css.startDate}</p>
                </div>
                <div className=" flex">
                  <label
                    htmlFor="feedbackPeriodFrom"
                    className="block custom-blue-text mb-1 whitespace-nowrap mt-5"
                  >
                    {endDate}
                  </label>
                  <p className="text-[#C8DBFF] mt-5 ml-4">{css.endDate}</p>
                </div>
                {role === IFOCUS_SUPER_ADMIN && (
                  <TrashIcon
                    onClick={() => handleDeleteActionItem(css.id)}
                    className="w-8 h-8 text-white hover:text-blue-600"
                  />
                )}
              </div>
            </div>
          ))
        ) : (
          <p className="text-[#C8DBFF] mt-4 ml-4 text-center">
            {actionNotPerform}
          </p>
        )}
      </div>
    );
  }

  const breadcrumbItems = [
    { name: "Home", href: "/" },
    { name: "Recent Surveys", current: false, href: "/css" },
    { name: "Customer Satisfaction Survey", current: true },
  ];
  const options = [
    { label: "Highly Satisfied", value: "HIGHLY_SATISFIED" },
    { label: "Satisfied", value: "SATISFIED" },
    { label: "Neutral", value: "NEUTRAL" },
    { label: "Dissatisfied", value: "DISSATISFIED" },
    { label: "Highly Dissatisfied", value: "HIGHLY_DISSATISFIED" },
  ];

  function formatDate(dateString) {
    const date = new Date(dateString);
    const options = { month: "long", year: "numeric" };
    return date.toLocaleDateString("en-US", options);
  }

  useEffect(() => {
    const fetchQuestions = async () => {
      try {
        const response = await apiRequest("GET", "/api/question-attributes");
        setQuestions(response.data);
        const surveyDataResponse = await apiRequest(
          "GET",
          `/api/css/${surveyId}`
        );
        setSurveyData(surveyDataResponse.data);
        if (
          surveyDataResponse.data &&
          Object.keys(surveyDataResponse.data.surveyQuestions).length > 0
        ) {
          const initialValuesObj = {};
          response.data.forEach((question) => {
            const questionId = question.id;
            const surveyQuestion =
              surveyDataResponse.data.surveyQuestions[questionId];
            if (surveyQuestion) {
              initialValuesObj[`question${questionId}`] = surveyQuestion.answer;
              initialValuesObj[`comment${questionId}`] =
                surveyQuestion.comments;
            } else {
              initialValuesObj[`question${questionId}`] = "";
              initialValuesObj[`comment${questionId}`] = "";
            }
          });
          setInitialValues(initialValuesObj);
        } else {
          const initialValuesObj = {};
          response.data.forEach((question) => {
            initialValuesObj[`question${question.id}`] = "";
            initialValuesObj[`comment${question.id}`] = "";
          });
          setInitialValues(initialValuesObj);
        }
      } catch (error) {
        toast.error(errorFetchData);
      }
    };
    fetchQuestions();
  }, []);

  const validationSchema = Yup.object().shape(
    questions.reduce((acc, question) => {
      acc[`question${question.id}`] = Yup.string().required(requiredField);
      acc[`comment${question.id}`] = Yup.string().when(
        `question${question.id}`,
        {
          is: (val) =>
            ["NEUTRAL", "DISSATISFIED", "HIGHLY_DISSATISFIED"].includes(val),
          then: () => Yup.string().required("*required"),
          otherwise: () => Yup.string(),
        }
      );
      return acc;
    }, {})
  );

  const handleSubmit = async (values, { setSubmitting }) => {
    const surveyQuestions = {};

    Object.keys(values).forEach((key) => {
      let questionId = "";
      if (key.includes("comment")) {
        questionId = key.replace("comment", "");
      } else if (key.includes("question")) {
        questionId = key.replace("question", "");
      }

      if (questionId) {
        if (!surveyQuestions[questionId]) {
          surveyQuestions[questionId] = {};
        }
        if (key.includes("comment")) {
          surveyQuestions[questionId].comments = values[key];
        } else if (key.includes("question")) {
          surveyQuestions[questionId].answer = values[key];
        }
      }
    });
    try {
      await apiRequest("PUT", `/api/css/update/${surveyId}`, {surveyQuestions});
      toast.success(thankYouFeedback);
      setSubmitting(false);
      navigate("/css-list");
    } catch (error) {
      toast.error(encounteredSubmitForm, {
        autoClose: 3000,
      });
      setSubmitting(false);
    }
  };
  const handleCancel = async () => {
    navigate("/css-list");
  };
  return (
    <div className="custom-bg-color ">
      <Header />
      <div className="px-4 py-8 pt-0 ">
        <div className="container  mx-auto mb-4">
          <Breadcrumb items={breadcrumbItems} />
        </div>
        <div className="container  mx-auto  border border-[#2B3E60] rounded-2xl ">
          <div className="px-4 py-8 bg-[#1E2B44] rounded-t-2xl">
            <div className="flex flex-col space-y-10 ">
              <img
                src="../assets/img/images/logo.png"
                alt=""
                className="w-24 h-10 block"
              />
              <div className="flex justify-between">
                <div className="flex flex-col self-stretch">
                  <h1 className="text-[#F1F6FF] text-2xl">{css}</h1>
                  <p className="text-[#8398C0] text-lg">
                    We invite you to participate in our Customer Survey, to let
                    us know your view on our <br /> performance. Your feedback
                    will help us meet your expectations and serve you better.
                  </p>
                  <br></br>
                  <p className="text-[#8398C0]">{kindlyRate}</p>
                </div>
                <div className="flex gap-28">
                  <div className="flex flex-col gap-5">
                    <div>
                      <h5 className="text-[#9DAECB] font-semibold text-lg">
                        {customerRep}
                      </h5>
                      <p className="text-[#DCE8FF] text-lg">
                        {surveyData && surveyData.customerRepresentativeName}
                      </p>
                    </div>
                    <div>
                      <h5 className="text-[#9DAECB] font-semibold text-lg">
                        {from}
                      </h5>
                      <p className="text-[#DCE8FF] text-lg">
                        {surveyData && formatDate(surveyData.fromDate)}{" "}
                      </p>
                    </div>
                  </div>
                  <div className="flex flex-col gap-5">
                    <div>
                      <h5 className="text-[#9DAECB] font-semibold text-lg">
                        {projectName}
                      </h5>
                      <p className="text-[#DCE8FF] text-lg">
                        {surveyData && surveyData.projectName}
                      </p>
                    </div>
                    <div>
                      <h5 className="text-[#9DAECB] font-semibold text-lg">
                        {to}
                      </h5>
                      <p className="text-[#DCE8FF] text-lg">
                        {surveyData && formatDate(surveyData.toDate)}{" "}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="container mx-auto px-4 py-8 ">
            <div className="flex flex-col lg:flex-row">
              <div className="w-full">
                {initialValues && (
                  <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                    validateOnMount={true}
                  >
                    <Form>
                      {questions?.map((question, index) => (
                        <div
                          key={question.id}
                          className="mb-4 rounded border border-[#2B3E60] p-7"
                          style={{ width: "100%" }}
                        >
                          <label className="block mb-2 text-[#C8DBFF]">
                            {`${index + 1}.` + " " + `${question.questionName}`}
                            *
                          </label>
                          <div className="flex flex-col">
                            <div className="flex">
                              {options.map((option, index) => (
                                <div
                                  key={option.label}
                                  className="mr-4 flex items-center"
                                >
                                  <Field
                                    type="radio"
                                    id={`${question.id}-${option}`}
                                    name={`question${question.id}`}
                                    value={option.value}
                                    disabled={
                                      roleHandler(adminSuperAdmin) ||
                                      (initialValues &&
                                        initialValues[
                                          `question${question.id}`
                                        ] !== "")
                                    }
                                    className="mr-2 appearance-none checked:bg-blue-700 border border-gray-500 rounded-full w-4 h-4 focus:outline-none focus:border-blue-500 bg-transparent"
                                  />
                                  <label
                                    htmlFor={`${question.id}-${option}`}
                                    className="radio-text text-[#728AB6]"
                                  >
                                    {option.label}
                                  </label>
                                </div>
                              ))}
                            </div>

                            <ErrorMessage
                              name={`question${question.id}`}
                              component="div"
                              className="text-red-500 text-sm mt-1"
                            />
                          </div>
                          <div className="mt-4">
                            <Field
                              as="textarea"
                              id={`comment${question.id}`}
                              placeholder="Comments"
                              name={`comment${question.id}`}
                              disabled={
                                roleHandler(adminSuperAdmin) ||
                                (initialValues &&
                                  initialValues[`question${question.id}`] !==
                                    "")
                              }
                              className="w-full px-3 py-2 border border-[#314466] rounded-md focus:outline-none focus:border-blue-500 bg-transparent text-white "
                            />
                            <ErrorMessage
                              name={`comment${question.id}`}
                              component="div"
                              className="text-red-500 text-sm mt-1"
                            />
                          </div>
                        </div>
                      ))}

                      <div
                        className="mb-4 rounded border border-[#2B3E60] p-7"
                        style={{ width: "100%" }}
                      >
                        <label
                          htmlFor="feedbackPeriodFrom"
                          className="block text-[#C8DBFF] mb-1 text-lg whitespace-nowrap mt-4"
                        >
                          {actionToImprove}
                        </label>
                        {/* <CssAction /> */}

                        {role === IFOCUS_ADM ? dynamicForm() : dynamicView()}
                      </div>

                      {roleHandler(customerManagerProjectManager) &&
                        !Object.keys(surveyData.surveyQuestions)?.length >
                          0 && (
                          <div className="flex justify-end gap-5">
                            <button
                              type="button"
                              onClick={handleCancel}
                              className="bg-transparent border custom-border w-40 lg:w-48 text-white py-2 px-4 rounded-md mt-4 lg:w-1/6 lg:h-12"
                            >
                              {cancel}
                            </button>
                            <button
                              type="submit"
                              className="bg-blue-500  custom-btn-color text-white py-2 px-4 rounded-md mt-4 w-44 h-12  border border-gray-300 p-3 lg:w-44 lg:h-12 lg:p-3"
                            >
                              {submitSurvey}
                            </button>
                          </div>
                        )}
                    </Form>
                  </Formik>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FeedbackForm;
