// ProjectModel.js
import React, { useState, useEffect } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import dayjs from "dayjs";
import { toast } from "react-toastify";
import DateInput from "../Generics/DateRangePicker/DateInput";
import { MinusCircleIcon, PlusCircleIcon } from "@heroicons/react/16/solid";
import { v4 as uuidv4 } from "uuid";
import { projectType, deleteValidate } from "../../constants/Roles";
import { apiGet, apiPost, apiPut } from "../../utils/apiHelper";
import LocalStorage from "../StorageUtil/LocalStorage";
import { projectModelMessage } from "../../constants/Validation";
const ProjectModel = ({
  onClose,
  initialValues,
  projects,
  onSubmit,
  client,
}) => {
  const { clientId } = LocalStorage.getResponseData();

  const [pocName, setPocName] = useState([
    {
      projectPocName: "",
      projectPocEmailAddress: "",
    },
  ]);
  const [customerId, setCustomerId] = useState("");
  const [customerList, setCustomerList] = useState([]);
  const [projectManger, setProjectManger] = useState([]);
  useEffect(() => {
    fetchCustomer();
  }, []);

  const fetchCustomer = async () => {
    try {
      const customer = await apiGet(projectModelMessage.api.customerManager);
      if (customer.status === 200) {
        setCustomerList(customer.data);
        if (customer.data.length > 0) {
          toast.success(projectModelMessage.message.customerManagerFetch);
        }
      }
    } catch (error) {
      toast.error(projectModelMessage.message.error);
    }
  };

  useEffect(() => {
    if (customerId !== "") {
      fetchProjectName();
    }
  }, [customerId]);

  const fetchProjectName = async () => {
    try {
      const projectRes = await apiGet(
        projectModelMessage.api.projectManager+`${customerId}`
      );
      if (projectRes.status === 200) {
        setProjectManger(projectRes.data);
        if (projectRes.data.length > 0) {
          toast.success(projectModelMessage.message.projectManagerSuccess);
        }
      }
    } catch (error) {
      toast.error(projectModelMessage.message.error);
    }
  };
  const validationSchema = Yup.lazy((values) =>
    Yup.object().shape({
      projectName: Yup.string()
        .required(projectModelMessage.message.projectNameRequired)
        .test(
          "is-unique",
          projectModelMessage.message.projectNameExit,
          function (value) {
            if (Object.keys(initialValues).length === 0) {
              // Convert the entered Project name to lowercase for comparison
              const enteredProjectName = value.toLowerCase().trim();
              // Check if the entered Project name already exists in the Projects array
              return !projects.some(
                (Project) =>
                  Project.projectName.toLowerCase() === enteredProjectName
              );
            }
            return true;
          }
        ),
      location: Yup.string()
        .required(projectModelMessage.message.locationRequired)
        .matches(/^[a-zA-Z\s]+$/, projectModelMessage.message.locationMust),
      projectPocName: Yup.string()
        .required(projectModelMessage.message.projectPocRequired)
        .matches(/^[a-zA-Z\s]+$/, projectModelMessage.message.projectPocMust),
      projectPocEmailAddress: Yup.string()
        .email(projectModelMessage.message.invalidProjectPocEmail)
        .required(projectModelMessage.message.projectPocEmailRequired)
        .matches(
          /@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
          projectModelMessage.message.invalidPOCEmail
        ),
      startDate: Yup.string().required(
        projectModelMessage.message.dateRequired
      ),
      endDate: Yup.string().required(projectModelMessage.message.dateRequired),
      projectType: Yup.string().required(
        projectModelMessage.message.projectTypeRequired
      ),
      projectTechnologies: Yup.string().required(
        projectModelMessage.message.projectTech
      ),
      projectStatus: Yup.string().required(
        projectModelMessage.message.projectTypeRequired
      ),
      remarks:
        values.projectStatus === projectModelMessage.message.active
          ? Yup.string()
              .matches(/^.{1,100}$/, projectModelMessage.message.remarkLength) // Remarks field is not mandatory for Active or if status is not selected
              .nullable()
          : Yup.string()
              .required(projectModelMessage.message.remarkRquired)
              .matches(/^.{1,100}$/, projectModelMessage.message.remarkLength),
    })
  );

  const disabledEndDate = (current) => {
    return current && current <= dayjs().endOf("day");
  };

  const disabledStartDate = (current) => {
    return current && current >= dayjs().endOf("day");
  };

  const handleSave = (values) => {
    values.client = { clientId: clientId ? clientId : client.clientId };
    if (JSON.stringify(initialValues) === "{}") {
      const add = pocName.map((item) => ({ ...item, ...values }));
      handleSaveProject(add);
    } else {
      const id = initialValues.id;
      const update = pocName.map((item) => ({ ...values, ...item, id }));
      handleSaveProject(update);
    }
  };
  const handlePocChange = (e, i) => {
    const selectedRep = projectManger.find(
      (rep) => rep.officialEmail === e.target.value
    );
    const newPoc = [...pocName];
    newPoc[i]["projectPocName"] = selectedRep ? selectedRep.name : "";
    newPoc[i]["projectPocEmailAddress"] = e.target.value;
    setPocName(newPoc);
  };

  const handleAddPoc = (i) => {
    const email = pocName[i].projectPocEmailAddress;
    if (email === "") {
      toast.error(projectModelMessage.message.emptyValue);
    } else {
      setPocName([
        ...pocName,
        {
          projectPocName: "",
          projectPocEmailAddress: "",
        },
      ]);
    }
  };

  const handleDeletePoc = (i) => {
    if (pocName.length < 2) {
      toast.error(projectModelMessage.message.lastValue);
    } else {
      const newPoc = [...pocName];
      newPoc.splice(i, 1);
      setPocName(newPoc);
    }
  };

  const handleSaveProject = async (values) => {
    onClose();
    try {
      if (JSON.stringify(initialValues) !== "{}") {
        await apiPut(projectModelMessage.api.updateBulk, values);
        toast.success(projectModelMessage.message.successUpdated);
      } else {
        await apiPost(projectModelMessage.api.addBulk, values);
        toast.success(projectModelMessage.message.successCreate);
      }
    } catch (error) {
      toast.error(projectModelMessage.message.error);
    }
  };

  function dynamicProjectAdd() {
    return (
      <div>
        {pocName.map((poc, index) => (
          <div
            className=" grid grid-cols-6 md:grid-cols-8 gap-4"
            key={uuidv4()}
          >
            <div className=" col-start-1 col-end-4">
              <label
                htmlFor="projectPocName"
                className="block custom-blue-text mb-1"
              >
                {projectModelMessage.message.projectPocName}
              </label>
              <select
                id={`projectPocName`}
                name={`projectPocName`}
                onChange={(e) => {
                  handlePocChange(e, index);
                }}
                className="w-full border border-[#1D2A42]  rounded-md px-3 py-2 bg-transparent"
              >
                <option>
                  {poc.projectPocName || projectModelMessage.message.select}
                </option>
                {projectManger.map((option, index) => (
                  <option key={option.id} value={option.officialEmail}>
                    {option.name}
                  </option>
                ))}
              </select>
            </div>
            <div className=" col-start-4 col-end-7">
              <label
                htmlFor="projectPocEmailAddress"
                className="block custom-blue-text mb-1"
              >
                {projectModelMessage.message.projectPocEmail}
              </label>
              <Field
                type="email"
                id={`projectPocEmailAddress`}
                name={`projectPocEmailAddress`}
                value={poc.projectPocEmailAddress}
                className="w-full border border-[#1D2A42]  rounded-md px-3 py-2 bg-transparent"
              />
              <ErrorMessage
                name="projectPocEmailAddress"
                component="div"
                className="text-red-500 text-sm mt-1"
              />
            </div>
            <div className=" flex mt-8 col-start-7 col-end-9 ">
              <PlusCircleIcon
                onClick={() => handleAddPoc(index)}
                className=" w-8 h-8 hover:text-blue-500"
              />
              {!deleteValidate(pocName) && (
                <MinusCircleIcon
                  onClick={() => handleDeletePoc(index)}
                  className=" w-8 h-8 ml-4 hover:text-blue-500"
                />
              )}
            </div>
          </div>
        ))}
      </div>
    );
  }
  return (
    <div className="text-[#D2D2D2] fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50 overflow-auto">
      <div className="modal-content w-full md:max-w-[80%] lg:max-w-[50%] rounded-lg p-8 transform transition-all duration-300 ease-in-out opacity-100 animate-slide-down">
        <h2 className="text-2xl font-semibold mb-4 mt-20">
          {projectModelMessage.message.projectDetails}
        </h2>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
        >
          {({ values, setFieldValue, validateForm }) => (
            <Form className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div>
                <label
                  htmlFor="projectName"
                  className="block custom-blue-text mb-1"
                >
                  {projectModelMessage.message.projectName}
                </label>
                <Field
                  type="text"
                  id="projectName"
                  name="projectName"
                  className="w-full border border-[#1D2A42]  rounded-md px-3 py-2 bg-transparent"
                />
                <ErrorMessage
                  name="projectName"
                  component="div"
                  className="text-red-500 text-sm mt-1"
                />
              </div>
              <div>
                <label
                  htmlFor="location"
                  className="block custom-blue-text mb-1"
                >
                  {projectModelMessage.message.location}
                </label>
                <Field
                  type="text"
                  id="location"
                  name="location"
                  className="w-full border border-[#1D2A42]  rounded-md px-3 py-2 bg-transparent"
                />
                <ErrorMessage
                  name="location"
                  component="div"
                  className="text-red-500 text-sm mt-1"
                />
              </div>
              <div>
                <label
                  htmlFor="projectType"
                  className="block custom-blue-text mb-1"
                >
                  {projectModelMessage.message.projectType}
                </label>
                <Field
                  as="select"
                  id="projectType"
                  name="projectType"
                  className="w-full border border-[#1D2A42] rounded-md px-3 py-2 bg-transparent"
                >
                  <option className="dropdown-content" value="">
                    {projectModelMessage.message.select}
                  </option>
                  <option
                    className="dropdown-content"
                    value={projectType.development}
                  >
                    {projectType.development}
                  </option>
                  <option
                    className="dropdown-content"
                    value={projectType.testing}
                  >
                    {projectType.testing}
                  </option>
                  <option
                    className="dropdown-content"
                    value={projectType.support}
                  >
                    {projectType.support}
                  </option>
                  <option
                    className="dropdown-content"
                    value={projectType.consultancy}
                  >
                    {projectType.consultancy}
                  </option>
                  <option
                    className="dropdown-content"
                    value={projectType.others}
                  >
                    {projectType.others}
                  </option>
                </Field>
                <ErrorMessage
                  name="projectType"
                  component="div"
                  className="text-red-500 text-sm mt-1"
                />
              </div>
              {values.projectType === "Others" ? (
                <div>
                  <label
                    htmlFor="otherType"
                    className="block custom-blue-text mb-1"
                  >
                    {projectModelMessage.message.otherType}
                  </label>
                  <Field
                    type="text"
                    id="otherType"
                    name="otherType"
                    className="w-full border border-[#1D2A42]  rounded-md px-3 py-2 bg-transparent"
                  />
                  <ErrorMessage
                    name="otherType"
                    component="div"
                    className="text-red-500 text-sm mt-1"
                  />
                </div>
              ) : null}

              <div>
                <label className="block custom-blue-text mb-1">
                  {projectModelMessage.message.customerManager}
                </label>
                <select
                  onChange={(e) => {
                    setCustomerId(e.target.value);
                  }}
                  className="w-full border border-[#1D2A42] rounded-md px-3 py-2 bg-transparent"
                >
                  <option>{projectModelMessage.message.select}</option>
                  {customerList.map((option, index) => (
                    <option key={option.id} value={option.id}>
                      {option.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className=" col-start-1 col-end-3">
                {dynamicProjectAdd()}
              </div>

              <div>
                <label
                  htmlFor="startDate"
                  className="block custom-blue-text mb-1"
                >
                  {projectModelMessage.message.projectStartDate}
                </label>
                <Field
                  type="date"
                  component={DateInput}
                  id="startDate"
                  name="startDate"
                  className="w-full border border-[#1D2A42]  rounded-md px-3 py-2 bg-transparent"
                  format={projectModelMessage.message.dateFormat}
                  fieldName="startDate"
                  disableDate={disabledStartDate}
                  defaultDate={initialValues?.startDate}
                />
              </div>
              <div>
                <label
                  htmlFor="endDate"
                  className="block custom-blue-text mb-1"
                >
                  {projectModelMessage.message.projectEndDate}
                </label>
                <Field
                  type="date"
                  component={DateInput}
                  id="endDate"
                  name="endDate"
                  className="w-full border border-[#1D2A42]  rounded-md px-3 py-2 bg-transparent"
                  format={"DD-MM-YYYY"}
                  fieldName="endDate"
                  disableDate={disabledEndDate}
                  defaultDate={initialValues?.endDate}
                />
              </div>
              <div>
                <label
                  htmlFor="projectTechnologies"
                  className="block custom-blue-text mb-1"
                >
                  {projectModelMessage.message.projectName}
                </label>
                <Field
                  type="text"
                  id="projectTechnologies"
                  name="projectTechnologies"
                  className="w-full border border-[#1D2A42]  rounded-md px-3 py-2 bg-transparent"
                />
                <ErrorMessage
                  name="projectTechnologies"
                  component="div"
                  className="text-red-500 text-sm mt-1"
                />
              </div>
              <div>
                <label
                  htmlFor="projectStatus"
                  className="block custom-blue-text mb-1"
                >
                  {projectModelMessage.message.projectStatus}
                </label>
                <Field
                  as="select"
                  id="projectStatus"
                  name="projectStatus"
                  className="w-full border border-[#1D2A42] rounded-md px-3 py-2 bg-transparent"
                >
                  <option className="dropdown-content" value="">
                    {projectModelMessage.message.select}
                  </option>
                  <option
                    className="dropdown-content"
                    value={projectModelMessage.message.active}
                  >
                    {projectModelMessage.message.active}
                  </option>
                  <option
                    className="dropdown-content"
                    value={projectModelMessage.message.inactive}
                  >
                    {projectModelMessage.message.inactive}
                  </option>
                  <option
                    className="dropdown-content"
                    value={projectModelMessage.message.closedCap}
                  >
                    {projectModelMessage.message.closedCap}
                  </option>
                </Field>
                <ErrorMessage
                  name="projectStatus"
                  component="div"
                  className="text-red-500 text-sm mt-1"
                />
              </div>
              <div className="md:col-span-2">
                <label
                  htmlFor="remarks"
                  className="block custom-blue-text mb-1"
                >
                  {projectModelMessage.message.remarks}
                </label>
                <Field
                  as="textarea"
                  id="remarks"
                  name="remarks"
                  className="w-full border border-[#1D2A42]  rounded-md px-3 py-2 bg-transparent"
                />
                <ErrorMessage
                  name="remarks"
                  component="div"
                  className="text-red-500 text-sm mt-1"
                />
              </div>
              <div className="md:col-span-2 flex gap-2 justify-end">
                <button
                  type="button"
                  className="text-white px-3 py-1 rounded-sm w-40 h-11"
                  onClick={onClose}
                >
                  {projectModelMessage.message.cancel}
                </button>
                <button
                  type="button"
                  onClick={() => handleSave(values)}
                  className="custom-btn-color text-white px-3 py-1 rounded-sm w-40 h-11"
                >
                  {projectModelMessage.message.save}
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default ProjectModel;
