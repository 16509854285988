import React, { useState, useEffect } from "react";
import { apiDelete, apiGet, apiPost, apiPut } from "../../utils/apiHelper";
import { useNavigate } from "react-router-dom";
import { newsTrendsMessage } from "../../constants/Validation";
import { toast } from "react-toastify";
const NewsTrendsManager = () => {
  const Navigate = useNavigate();
  const [trends, setTrends] = useState([]);
  const [newTrend, setNewTrend] = useState({ name: "", url: "" });
  const [editing, setEditing] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchTrends();
  }, []);

  const fetchTrends = async () => {
    setLoading(true);
    try {
      const response = await apiGet(newsTrendsMessage.api.newstrends);
      setTrends(response.data);
    } catch (error) {
      console.error(newsTrendsMessage.message.error, error);
    } finally {
      setLoading(false);
    }
  };

  const handleAddTrend = async () => {
    if (!newTrend.name || !newTrend.url)
      return toast.error(newsTrendsMessage.message.requiredFields);
    try {
      const response = await apiPost(
        newsTrendsMessage.api.newstrends,
        newTrend
      );
      setTrends([...trends, response.data]);
      setNewTrend({ name: "", url: "" });
    } catch (error) {
      console.error(newsTrendsMessage.message.error, error);
    }
  };

  const handleEditTrend = async () => {
    if (!editing) return;
    try {
      await apiPut(
        newsTrendsMessage.api.updateNewsTrends+`${editing.id}`,
        editing
      );
      setTrends(
        trends.map((trend) =>
          trend.id === editing.id ? { ...editing } : trend
        )
      );
      setEditing(null);
    } catch (error) {
      console.error(newsTrendsMessage.message.error, error);
    }
  };

  const handleDeleteTrend = async (id) => {
    try {
      await apiDelete(newsTrendsMessage.api.deleteNewsTrends+`${id}`);
      setTrends(trends.filter((trend) => trend.id !== id));
    } catch (error) {
      console.error(newsTrendsMessage.message.error, error);
    }
  };

  return (
    <div className="min-h-screen bg-gray-900 text-white flex flex-col items-center py-10">
      <h1 className="text-3xl font-bold mb-6">
        {newsTrendsMessage.message.manageNewsTrends}
      </h1>
      <div className="w-3/4">
        <div className="mb-4 flex gap-4">
          <input
            type="text"
            placeholder="Name"
            className="w-1/2 p-2 bg-gray-800 border border-gray-700 rounded"
            value={editing ? editing.name : newTrend.name}
            onChange={(e) =>
              editing
                ? setEditing({ ...editing, name: e.target.value })
                : setNewTrend({ ...newTrend, name: e.target.value })
            }
          />
          <input
            type="text"
            placeholder="URL"
            className="w-1/2 p-2 bg-gray-800 border border-gray-700 rounded"
            value={editing ? editing.url : newTrend.url}
            onChange={(e) =>
              editing
                ? setEditing({ ...editing, url: e.target.value })
                : setNewTrend({ ...newTrend, url: e.target.value })
            }
          />
          <button
            onClick={editing ? handleEditTrend : handleAddTrend}
            className="px-4 py-2 bg-blue-600 rounded hover:bg-blue-700"
          >
            {editing
              ? newsTrendsMessage.message.update
              : newsTrendsMessage.message.add}
          </button>
          <button
            onClick={() => Navigate(-1)}
            type="danger"
            className="px-4 py-2 bg-red-600 rounded hover:bg-red-700"
          >
            {newsTrendsMessage.message.Back}
          </button>
        </div>
        {loading ? (
          <p>{newsTrendsMessage.message.loading}</p>
        ) : (
          <table className="w-full table-auto border border-gray-700">
            <thead>
              <tr className="bg-gray-800">
                <th className="p-2 border border-gray-700">
                  {newsTrendsMessage.message.name}
                </th>
                <th className="p-2 border border-gray-700">
                  {newsTrendsMessage.message.url}
                </th>
                <th className="p-2 border border-gray-700">
                  {newsTrendsMessage.message.actions}
                </th>
              </tr>
            </thead>
            <tbody>
              {trends.map((trend) => (
                <tr key={trend.id} className="hover:bg-gray-800">
                  <td className="p-2 border border-gray-700">{trend.name}</td>
                  <td className="p-2 border border-gray-700">
                    <a
                      href={trend.url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {trend.url}
                    </a>
                  </td>
                  <td className="p-2 border border-gray-700 flex gap-2">
                    <button
                      onClick={() => setEditing(trend)}
                      className="px-2 py-1 bg-yellow-600 rounded hover:bg-yellow-700"
                    >
                      {newsTrendsMessage.message.edit}
                    </button>
                    <button
                      onClick={() => handleDeleteTrend(trend.id)}
                      className="px-2 py-1 bg-red-600 rounded hover:bg-red-700"
                    >
                      {newsTrendsMessage.message.delete}
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
};

export default NewsTrendsManager;
