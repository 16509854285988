import React, { useState, useEffect, useRef } from "react";
import { ToastContainer, toast } from "react-toastify";
import Header from "../Layout/Header/Header";
import Breadcrumb from "../Layout/Header/Breadcrumb";
import WeeklyUploadBtn from "../Reports/WeeklyUploadBtn";
import {
  dateConverter,
  handleDownloadPdf,
  getCurrentWeek,
  tableLoaderHandler,
  weekOptions,
  clientName,
  projectName,
} from "../../utils/dateConverter";
import { v4 as uuidv4 } from "uuid";
import { ArrowDownTrayIcon } from "@heroicons/react/24/outline";
import {
  COORDINATOR,
  allPlatforms,
  allWeeks,
  dataNotFound,
  errorFetchData,
  fileUploadSuccess,
  meWatch,
  tcExecuted,
  role,
  versionEnv,
  viewMore,
} from "../../constants/Roles";
import { useMyContext } from "../../context/ContextApi";
import { apiGet } from "../../utils/apiHelper";

const WeeklyTable = () => {
  const { state } = useMyContext();
  const conponentPDF = useRef(null);
  const currentYear = new Date().getFullYear();
  const years = Array.from({ length: 10 }, (_, i) => currentYear - i); // Last 10 years
  const [selectedYear, setSelectedYear] = useState(currentYear);
  const handleYearChange = (event) => {
    setSelectedYear(event.target.value);
  };
  const breadcrumbs = [
    { name: "Home", href: "/dashboard", current: true },
    { name: "Clients", href: "/clients", current: true },
    {
      name: `${
        clientName || JSON.parse(state?.navClientNameId)?.clientName
      } Projects`,
      href: "/projects",
      current: true,
    },
    {
      name: `${projectName || state?.navProjectName} Reports`,
      href: "/report",
      current: true,
    },
    {
      name: `${projectName || state?.navProjectName} Weekly Report`,
      href: "/weeklytable",
      current: false,
    },
  ];

  const [selectedWeek, setSelectedWeek] = useState(getCurrentWeek());
  const [selectedPlatform, setSelectedPlatform] = useState("");
  const [tableData, setTableData] = useState([]);
  const [dataToShow, setDataToShow] = useState([]);
  const [isTableDataLoading, setTableDataIsLoading] = useState(true);
  const [isShowMoreThenTen, setIsShowMoreThenTen] = useState(false);

  const platformOptions = [
    "RW",
    "Android",
    "CTV",
    "Android TV",
    "iOS",
    "ADHOC",
  ];

  const tableHeadings = [
    "PLATFORM",
    "VERSION/ENVIRONMENT",
    "DETAILS",
    "START DATE",
    "END DATE",
    "BUILD TYPE",
    "TICKET VERIFIED",
    "TC'S EXECUTED/DRAFTED",
    "CRITICAL BUGS",
  ];

  const fetchData = async () => {
    try {
      let url;
      if (selectedPlatform === "") {
        url = `/reports/get/${selectedWeek}/${selectedYear}?week=${selectedWeek}&platform=${selectedPlatform}`;
      } else {
        url = `/reports?week=${selectedWeek}&${selectedYear}&platform=${selectedPlatform}`;
      }

      const response = await apiGet(url);
      if (response.status === 200) {
        if (response.data.length > 0) {
          setTableData(response.data);
          setDataToShow(response.data.slice(0, 8));
          setIsShowMoreThenTen(true);
        } else {
          setTableData([]);
          setDataToShow([]);
          setIsShowMoreThenTen(false);
          toast.error(dataNotFound);
        }

        setTableDataIsLoading(Array(response?.data)?.length > 0 ? false : true);
      }
    } catch (error) {
      toast.error(errorFetchData);
    }
  };

  useEffect(() => {
    fetchData();
  }, [selectedWeek, selectedPlatform]);

  const handleWeekChange = (event) => {
    const selectedWeekValue = event.target.value;
    setSelectedWeek(selectedWeekValue);
  };

  const handlePlatformChange = (event) => {
    const selectedPlatformValue = event.target.value;
    setSelectedPlatform(selectedPlatformValue);
  };

  const handleFileUploadSuccess = () => {
    toast.success(fileUploadSuccess);
  };

  const handleShow = () => {
    setDataToShow(tableData);
    setIsShowMoreThenTen(!isShowMoreThenTen);
  };
  const headingHandler = (keyword) => {
    if (keyword === tcExecuted) {
      return (
        <>
          TC'S EXECUTED/
          <br />
          DRAFTED
        </>
      );
    } else if (keyword === versionEnv) {
      return (
        <>
          VERSION/
          <br />
          ENVIRONMENT
        </>
      );
    } else {
      return keyword;
    }
  };
  return (
    <div className="bg-gray-900 min-h-screen">
      <Header />
      <ToastContainer />
      <div className="bg-gray-900 mt-2 items-center justify-center">
        <div className="mx-auto  px-4 py-16 sm:px-6 sm:py-6  lg:px-8 custom-bg-color">
          {role !== COORDINATOR && <Breadcrumb items={breadcrumbs} />}
          <div className="w-full flex justify-between items-center">
            <div></div>
            <div className="flex items-center">
              <WeeklyUploadBtn
                userRole={role}
                handleFileUploadSuccess={handleFileUploadSuccess}
              />
            </div>
          </div>

          <div className="flex flex-col sm:flex-row items-center justify-between mt-4">
            <h3 className="text-3xl font-medium leading-6 text-white mt-8 sm:mt-0">
              {meWatch}
            </h3>

            <div className="flex flex-col sm:flex-row sm:p-2 items-center justify-between mt-4 sm:mt-0">
              <div className=" md:flex">
                <div
                  className="flex ml-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                  onClick={() =>
                    handleDownloadPdf(conponentPDF, selectedWeek, tableData)
                  }
                >
                  <p className=" text-white font-bold mr-2">PDF</p>
                  <ArrowDownTrayIcon className=" w-8 h-8 text-white hover:text-blue-500" />
                </div>
              </div>
              <select
                id="yearSelect"
                value={selectedYear}
                onChange={handleYearChange}
                className="ml-4 bg-gray-900 border border-gray-700 hover:border-gray-500 px-4 py-2 pr-8 rounded shadow leading-tight text-[#6B778E] focus:outline-none focus:shadow-outline"
              >
                <option value="">Select Year</option>
                {years.map((year) => (
                  <option key={uuidv4()} value={year}>
                    {year}
                  </option>
                ))}
              </select>
              <select
                value={selectedWeek}
                onChange={handleWeekChange}
                className="ml-4 bg-gray-900 border border-gray-700 hover:border-gray-500 px-4 py-2 pr-8 rounded shadow leading-tight text-[#6B778E] focus:outline-none focus:shadow-outline"
              >
                <option value="">{allWeeks}</option>
                {weekOptions.map((week, index) => (
                  <option key={uuidv4()} value={week}>
                    {week}
                  </option>
                ))}
              </select>
              <select
                value={selectedPlatform}
                onChange={handlePlatformChange}
                className="ml-4 bg-gray-900 border border-gray-700 hover:border-gray-500 px-4 py-2 pr-8 rounded shadow leading-tight text-[#6B778E] focus:outline-none focus:shadow-outline"
              >
                <option value="">{allPlatforms}</option>
                {platformOptions.map((platform, index) => (
                  <option
                    key={uuidv4()}
                    value={platform}
                    className="border-t border-gray-700"
                  >
                    {platform}
                  </option>
                ))}
              </select>
            </div>
          </div>

          <div className=" mt-4">
            {tableData.length === 0 ? (
              tableLoaderHandler(isTableDataLoading)
            ) : (
              <div
                ref={conponentPDF}
                className="border border-solid overflow-x-auto border-gray-700 rounded-lg p-4 mb-4"
              >
                <table className="min-w-full divide-y divide-gray-700">
                  <thead className="bg-gray-800">
                    <tr>
                      {tableHeadings.map((heading, index) => (
                        <th
                          key={uuidv4()}
                          className="px-4 h-14 border border-slate-300 text-left text-xs font-medium text-nowrap text-gray-300 uppercase tracking-wider "
                        >
                          {headingHandler(heading)}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody className="bg-gray-900 divide-y divide-gray-900">
                    {dataToShow.map((row, index) => (
                      <tr key={uuidv4()}>
                        <td className="border border-slate-300 px-4 h-14 whitespace-nowrap text-sm text-gray-300">
                          {row.platform}
                        </td>
                        <td className="border border-slate-300 px-4 h-14 whitespace-nowrap text-sm text-gray-300">
                          {row.versionEnvironment
                            ?.split(/\r?\n/)
                            ?.map((line, index) => (
                              <span key={index}>
                                {line}
                                {index <
                                  row.versionEnvironment?.split(/\r?\n/)
                                    .length -
                                    1 && <br />}
                              </span>
                            ))}
                        </td>
                        <td className="border border-slate-300 px-4 h-14 text-ellipsis text-sm text-gray-300">
                          {row.details?.split(/\r?\n/)?.map((line, index) => (
                            <span key={index}>
                              {line}
                              {index <
                                row.details?.split(/\r?\n/).length - 1 && (
                                <br />
                              )}
                            </span>
                          ))}
                        </td>
                        <td className=" border border-slate-300 px-4 h-14 whitespace-nowrap text-sm text-gray-300">
                          {row.fromDate !== ""
                            ? dateConverter(row.fromDate)
                            : "--"}
                        </td>
                        <td className="border border-slate-300 px-4 h-14 whitespace-nowrap text-sm text-gray-300">
                          {row.toDate !== "" ? dateConverter(row.toDate) : "--"}
                        </td>
                        <td className="border border-slate-300 px-4 h-14 whitespace-nowrap text-sm text-gray-300">
                          {row.buildType}
                        </td>
                        <td className="border border-slate-300 px-4 h-14 whitespace-nowrap text-sm text-gray-300">
                          {isNaN(Math.floor(row.numberOfTicketsVerified))
                            ? "NA"
                            : Math.floor(row.numberOfTicketsVerified)}
                        </td>
                        <td className="border border-slate-300 px-4 h-14 whitespace-nowrap text-sm text-gray-300">
                          {isNaN(Math.floor(row.numberOfTCsExecuted))
                            ? "NA"
                            : Math.floor(row.numberOfTCsExecuted)}
                        </td>
                        <td className="border border-slate-300 px-4 h-14 whitespace-nowrap text-sm text-gray-300">
                          {isNaN(Math.floor(row.criticalBugs))
                            ? "NA"
                            : Math.floor(row.criticalBugs)}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
            {isShowMoreThenTen && (
              <div className="flex justify-end">
                <button
                  className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                  onClick={handleShow}
                >
                  {viewMore}
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default WeeklyTable;
