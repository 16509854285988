import React, { useState, useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { adminSuperAdmin, roleHandler } from "../../constants/Roles";
import {  EyeIcon, MapPinIcon, PencilSquareIcon, WrenchScrewdriverIcon } from "@heroicons/react/24/outline";
import { CogIcon, } from "@heroicons/react/16/solid";
import { useMyContext } from "../../context/ContextApi";
import { toast } from "react-toastify";
import { projectMessage } from "../../constants/Validation";

const ProjectCard = ({ project, handleEdit,viewTable }) => {
  const { dispatch } = useMyContext();
  const [showOptions, setShowOptions] = useState(false);
  const dropdownRef = useRef(null);
  const navigate = useNavigate();
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowOptions(false);
      }
    };

    document.addEventListener(projectMessage.message.mouseDown, handleClickOutside);

    return () => {
      document.removeEventListener(projectMessage.message.mouseDown, handleClickOutside);
    };
  }, []);

  const handleOptionsToggle = () => {
    setShowOptions(!showOptions);
  };

  const handleUpdate = (project) => {
    handleEdit(project);
  };

  const location = useLocation();
  const currentURL = location.pathname;
  const projectInfo=JSON.stringify({
    projectName: project?.projectName,
    id: project?.id,
  })

  const handleClientClick = async (project) => {
    if(project.projectName!==projectMessage.message.meWatch)
    {
      toast.error(projectMessage.message.reportNotUploaded)
    }
    else{
    localStorage.setItem(projectMessage.message.navProjectName, projectInfo);
    dispatch({ type: projectMessage.message.setProjectName, payload:project?.projectName  });
    if (currentURL === projectMessage.url.projects) {
      navigate(projectMessage.url.report, { state: { client: project } });
    }
  }
  };
  const handleViewTable=()=>{
    viewTable(true)
  };



  return (
    <div
      key={project.id}
      onClick={() => {
        if (String(project.projectStatus).toLowerCase() === projectMessage.message.active) {
          handleClientClick(project);
        }
      }}
      className="bg card-header rounded-lg shadow-md cursor-pointer transition duration-300 ease-in-out hover:scale-105"
    >
      <div className="px-4 py-2">
        <div className="flex justify-between h-14 items-center">
          <h1 className="text-base text-[#DAE7FF] ">{project.projectName}</h1>
          {String(project.projectStatus).toLowerCase() === projectMessage.message.closed && (
            <div className="text-[#DAE7FF] border-2 p-1 rounded text-xs ">
              {projectMessage.message.closed}
            </div>
          )}
          {String(project.projectStatus).toLowerCase() === projectMessage.message.inActive && (
            <div className="text-[#DAE7FF] border-2 p-1 rounded text-xs ">
              {projectMessage.message.inActive}
            </div>
          )}
          {roleHandler(adminSuperAdmin) &&
            String(project.projectStatus).toLowerCase() == "active" && (
              <div
                className="flex relative"
                ref={dropdownRef}
                onClick={(e) => {
                  e.stopPropagation();
                  handleOptionsToggle();
                }}
              >
                {currentURL === projectMessage.url.manageProjects && (
                  <PencilSquareIcon className=" w-8 h-8 text-gray-300"  onClick={() => {
                    handleUpdate(project);
                  }}/>
                  
                )}
                {currentURL === projectMessage.url.manageProjects && (
                  <EyeIcon className=" w-8 h-8 text-gray-300 ml-4" onClick={handleViewTable}/>

                )}
              </div>
            )}
        </div>
      </div>
      <div className="p-4 bg card-content rounded-bl-lg rounded-br-lg">
        <div>
          <div className="border-b border-[#1E2B43] mb-4 pb-4 flex items-center">
          <WrenchScrewdriverIcon className=" w-6 h-6 custom-blue-text"/>
            <p className="text-sm custom-blue-text flex-grow ml-2">
              {project.projectType}
            </p>
          </div>
          <div className="flex items-center mb-4 pb-4 border-b border-[#1E2B43]">
          <CogIcon className=" w-6 h-6 custom-blue-text"/>
            <p className="text-sm custom-blue-text flex-grow ml-2">
              {project.projectTechnologies}
            </p>
          </div>
          <div className="flex items-center">
          <MapPinIcon className=" w-6 h-6 custom-blue-text"/>

            <p className="text-sm custom-blue-text flex-grow ml-2">
              {project.location}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProjectCard;
