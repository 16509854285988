import React, { useState, useEffect } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { v4 as uuidv4 } from "uuid";
import { PlusCircleIcon, MinusCircleIcon } from "@heroicons/react/16/solid";
import { apiGet, apiPost } from "../../utils/apiHelper";
import { surveyRequest } from "../../constants/Validation";
import LocalStorage from "../StorageUtil/LocalStorage";

const SurveyRequest = ({ onClose, initialValues }) => {
  const [customerRepresentatives, setCustomerRepresentatives] = useState([]);
  const [projects, setProjects] = useState([]);
  const [clients, setClients] = useState([]);
  const [clientId, setClientId] = useState({ clientId: "", clientName: "" });
  const [questionAnswer, setQuestionAnswer] = useState([]);
  const [questionName, setQuestionName] = useState([
    {
      id: "",
    },
  ]);
  const handleQuestionChange = (e, i) => {
    const newQuestion = [...questionName];
    newQuestion[i]["id"] = e.target.value;
    setQuestionName(newQuestion);
  };

  const handleAddQuestion = (i) => {
    const id = questionName[i].id;
    if (id === "") {
      toast.error(surveyRequest.message.emptyValue);
    } else {
      setQuestionName([
        ...questionName,
        {
          id: "",
        },
      ]);
    }
  };

  const handleDeleteQuestion = (i) => {
    if (questionName.length < 2) {
      toast.error(surveyRequest.message.lastValue);
    } else {
      const newQuestion = [...questionName];
      newQuestion.splice(i, 1);
      setQuestionName(newQuestion);
    }
  };

  const { id } = LocalStorage.getResponseData();
  useEffect(() => {
    fetchDropdownOptions();
    fetchQuestionOptions();
  }, []);
  const fetchDropdownOptions = async () => {
    try {
      const customerRepData = await apiGet(surveyRequest.api.projectManager);
      const clientData = await apiGet(surveyRequest.api.clients);
      setCustomerRepresentatives(customerRepData.data);
      setClients(clientData.data);
    } catch (error) {
      toast.error(surveyRequest.message.error);
    }
  };
  const fetchQuestionOptions = async () => {
    try {
      const response = await apiGet(surveyRequest.api.questionAttributes);
      if (response.status == 200) {
        setQuestionAnswer(response.data);
      }
    } catch (error) {
      toast.error(surveyRequest.message.error);
    }
  };

  const handleClientChange = async (e) => {
    const selectedClient = clients.find(
      (client) => client.clientName === e.target.value
    );

    setClientId({
      clientId: selectedClient.clientId,
      clientName: e.target.value,
    });
    try {
      const response = await apiGet(
        surveyRequest.api.projects+`${selectedClient.clientId}`
      );
      setProjects(response.data);
    } catch (error) {
      toast.error(surveyRequest.message.error);
    }
  };

  const onSubmit = async (values) => {
    // Find the selected project object based on the project name
    const selectedProject = projects.find(
      (project) => project.projectName === values.projectName
    );
    const customerManager = customerRepresentatives.find(
      (cm) => cm.name === values.customerRepresentativeName
    );
    // Map the form values to the API payload
    const apiPayload = {
      customerRepresentativeName: values.customerRepresentativeName,
      customerRepresentativeEmailId: values.customerRepresentativeEmail,
      projectName: values.projectName,
      // Assuming you have some IDs for clientManagerId, clientId, projectId, etc.
      clientManagerId: id,
      fromDate: values.feedbackPeriodFrom,
      toDate: values.feedbackPeriodTo,
      clientId: clientId.clientId,
      customerManagerId: customerManager ? customerManager.id : "",
      projectId: selectedProject ? selectedProject.id : "",
      surveyLink: "", // You may replace this with the actual survey link or fetch it dynamically
    };

    try {
      const response = await apiPost(
        surveyRequest.api.initiate,
        apiPayload
      );
      if (response.status === 201) {
        onSaveQuestion(response.data.id);
        toast.success(surveyRequest.message.cssInitiated);
      }
    } catch (error) {
      toast.error(surveyRequest.message.failedInitiateCss);
    }
    onClose();
  };

  const onSaveQuestion = async (id) => {
    try {
      await apiPost(
        surveyRequest.api.apiCss+`${id}`+surveyRequest.api.question,
        questionName
      );
    } catch (error) {
      toast.error(surveyRequest.message.error);
    }
  };

  const validationSchema = Yup.object().shape({
    customerRepresentativeName: Yup.string().required(
      surveyRequest.validation.customer.required
    ),
    customerRepresentativeEmail: Yup.string()
      .email(surveyRequest.validation.email.invalid)
      .required(surveyRequest.validation.email.required),
    projectName: Yup.string().required(
      surveyRequest.validation.project.required
    ),
    feedbackPeriodFrom: Yup.date()
      .max(new Date(), surveyRequest.validation.feedback.period)
      .nullable()
      .required(surveyRequest.validation.feedback.startDate),
    feedbackPeriodTo: Yup.date()
      .max(new Date(), surveyRequest.validation.feedback.period)
      .nullable()
      .when(
        surveyRequest.validation.feedback.periodFrom,
        (feedbackPeriodFrom, schema) =>
          feedbackPeriodFrom &&
          schema.min(
            feedbackPeriodFrom,
            surveyRequest.validation.feedback.endDate
          )
      )
      .required(surveyRequest.validation.feedback.endDateRequired),
  });
  const getQuestionById = (id) => {
    const questionObject = questionAnswer.find((error) => error.id === id);
    return questionObject
      ? questionObject.questionName
      : surveyRequest.message.select;
  };

  function dynamicQuestionAdd() {
    return (
      <div>
        {questionName.map((question, index) => (
          <div
            className=" grid grid-cols-6 md:grid-cols-8 gap-4"
            key={uuidv4()}
          >
            <div className=" col-start-1 col-end-7">
              <label htmlFor="id" className="block custom-blue-text mb-1">
                {surveyRequest.message.questionField}
              </label>
              <select
                id={`id`}
                name={`id`}
                onChange={(e) => {
                  handleQuestionChange(e, index);
                }}
                className="w-full border border-[#1D2A42]  rounded-md px-3 py-2 bg-transparent"
              >
                <option>{getQuestionById(question.id)}</option>
                {questionAnswer.map((option, index) => (
                  <option key={uuidv4()} value={option.id}>
                    {option.questionName}
                  </option>
                ))}
              </select>
            </div>
            <div className=" flex mt-8 col-start-7 col-end-9 ">
              <PlusCircleIcon
                onClick={() => handleAddQuestion(index)}
                className=" w-8 h-8 hover:text-blue-500"
              />
              <MinusCircleIcon
                onClick={() => handleDeleteQuestion(index)}
                className=" w-8 h-8 ml-4 hover:text-blue-500"
              />
            </div>
          </div>
        ))}
      </div>
    );
  }

  return (
    <div className="text-[#D2D2D2] fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
      <div className="modal-content w-full md:max-w-[100%] lg:max-w-[50%] rounded-lg p-8 transform transition-all duration-300 ease-in-out opacity-100 animate-slide-down">
        <h2 className="text-2xl font-semibold mb-4">
          {surveyRequest.message.initiateSurvey}
        </h2>
        <p className="custom-blue-text mb-4">
          {surveyRequest.message.enterDetail}
        </p>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {({ values, setFieldValue }) => (
            <Form className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div>
                <label
                  htmlFor="customerRepresentativeName"
                  className="block custom-blue-text mb-1"
                >
                  {surveyRequest.message.customerRepresentativeName}
                </label>
                <Field
                  as="select"
                  id="customerRepresentativeName"
                  name="customerRepresentativeName"
                  className="w-full border border-[#1D2A42] rounded-md px-3 py-2 bg-transparent"
                  onChange={(e) => {
                    // Find the selected representative
                    const selectedRep = customerRepresentatives.find(
                      (rep) => rep.name === e.target.value
                    );

                    // Set the email value based on the selected representative
                    setFieldValue(
                      "customerRepresentativeEmail",
                      selectedRep ? selectedRep.officialEmail : ""
                    );
                    setFieldValue("customerRepresentativeName", e.target.value);
                  }}
                >
                  <option value="">{surveyRequest.message.select}</option>
                  {customerRepresentatives.map((rep) => (
                    <option key={rep.id} value={rep.name}>
                      {rep.name}
                    </option>
                  ))}
                </Field>
                <ErrorMessage
                  name="customerRepresentativeName"
                  component="div"
                  className="text-red-500 text-sm mt-1"
                />
              </div>
              <div>
                <label
                  htmlFor="customerRepresentativeEmail"
                  className="block custom-blue-text mb-1"
                >
                  {surveyRequest.message.customerRepresentativeEmail}
                </label>
                <Field
                  type="email"
                  id="customerRepresentativeEmail"
                  name="customerRepresentativeEmail"
                  className="w-full border border-[#1D2A42] rounded-md px-3 py-2 bg-transparent"
                  disabled={values.customerRepresentativeEmail !== ""}
                />
                <ErrorMessage
                  name="customerRepresentativeEmail"
                  component="div"
                  className="text-red-500 text-sm mt-1"
                />
              </div>
              <div>
                <label
                  htmlFor="clientName"
                  className="block custom-blue-text mb-1"
                >
                  {surveyRequest.message.clientName}
                </label>
                <Field
                  as="select"
                  id="clientName"
                  name="clientName"
                  className="w-full border border-[#1D2A42] rounded-md px-3 py-2 bg-transparent"
                  value={clientId.clientName || ""}
                  onChange={(e) => {
                    handleClientChange(e);
                  }}
                >
                  <option value="">{surveyRequest.message.select}</option>
                  {clients.map((client, index) => (
                    <option key={index} value={client.clientName}>
                      {client.clientName}
                    </option>
                  ))}
                </Field>
                <ErrorMessage
                  name="clinetName"
                  component="div"
                  className="text-red-500 text-sm mt-1"
                />
              </div>

              <div>
                <label
                  htmlFor="feedbackPeriodFrom"
                  className="block custom-blue-text mb-1"
                >
                  {surveyRequest.message.feedbackPeriod}
                </label>
                <div className="flex">
                  <Field
                    type="date"
                    id="feedbackPeriodFrom"
                    name="feedbackPeriodFrom"
                    className="w-full border border-[#1D2A42] rounded-md px-3 py-2 bg-transparent mr-5"
                    max={new Date().toISOString().split("T")[0]}
                    onChange={(e) =>
                      setFieldValue("feedbackPeriodFrom", e.target.value)
                    }
                  />
                  <Field
                    type="date"
                    id="feedbackPeriodTo"
                    name="feedbackPeriodTo"
                    className="w-full border border-[#1D2A42] rounded-md px-3 py-2 bg-transparent"
                    max={new Date().toISOString().split("T")[0]}
                    onChange={(e) =>
                      setFieldValue("feedbackPeriodTo", e.target.value)
                    }
                  />
                </div>

                <ErrorMessage
                  name="feedbackPeriodTo"
                  component="div"
                  className="text-red-500 text-sm mt-1"
                />
              </div>
              <div>
                <label
                  htmlFor="projectName"
                  className="block custom-blue-text mb-1"
                >
                  {surveyRequest.message.projectName}
                </label>
                <Field
                  as="select"
                  id="projectName"
                  name="projectName"
                  className="w-full border border-[#1D2A42] rounded-md px-3 py-2 bg-transparent"
                >
                  <option value="">Select...</option>
                  {projects.map((project) => (
                    <option key={project.id} value={project.projectName}>
                      {project.projectName}
                    </option>
                  ))}
                </Field>
                <ErrorMessage
                  name="projectName"
                  component="div"
                  className="text-red-500 text-sm mt-1"
                />
              </div>
              <div className=" col-start-1 col-end-3">
                {dynamicQuestionAdd()}
              </div>
              <div className="md:col-span-2 flex gap-2 justify-end">
                <button
                  type="button"
                  className="text-white px-3 py-1 rounded-sm w-40 h-11"
                  onClick={onClose}
                >
                  {surveyRequest.message.cancel}
                </button>
                <button
                  type="submit"
                  className="custom-btn-color text-white px-3 py-1 rounded-sm w-40 h-11"
                >
                  {surveyRequest.message.initiate}
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default SurveyRequest;
