// ProjectList.js
import React, { useState, useEffect } from "react";
import UserCard from "./UserCard";
import ProjectMangerCard from "./ProjectMangerCard";
import { CUSTOMER_MANAGER, PROJECT_MANAGER } from "../../constants/Roles";
import { apiGet } from "../../utils/apiHelper";
import { toast } from "react-toastify";
import { userListMessage } from "../../constants/Validation";
const UserList = ({ user, handleEdit, isLoading, viewTable }) => {
  const [userData, setUserdata] = useState([]);
  const [callProjectManager, setCallProjectManager] = useState(false);
  const handleProjectManager = () => {
    setCallProjectManager(!callProjectManager);
    fetchData();
  };
  const id = user.find((item) => item.role === CUSTOMER_MANAGER)?.id;
  const fetchData = async () => {
    if (!id) {
      toast.error(userListMessage.message.customerManagerNotFound);
    } else {
      try {
        const response = await apiGet(
          userListMessage.api.projectManager+`${id}`
        );
        setUserdata(response.data);
      } catch (error) {
        toast.error(userListMessage.message.error);
      }
    }
  };
  return (
    <>
      {callProjectManager === false ? (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-7 mt-4">
          {user
            .filter((item) => item.role !== PROJECT_MANAGER)
            .map((usr) => (
              <UserCard
                key={usr.id}
                projectManager={handleProjectManager}
                user={usr}
                handleEdit={handleEdit}
                viewTable={viewTable}
              />
            ))}
        </div>
      ) : (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-7 mt-4">
          {userData.map((usr) => (
            <ProjectMangerCard
              key={usr.id}
              user={usr}
              handleEdit={handleEdit}
              viewTable={viewTable}
            />
          ))}
        </div>
      )}
    </>
  );
};

export default UserList;
