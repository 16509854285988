import LocalStorage from "../components/StorageUtil/LocalStorage";

export const IFOCUS_ADM = "IFOCUS_ADM";
export const CUSTOMER_MANAGER = "CUSTOMER_MANAGER";
export const IFOCUS_SUPER_ADMIN = "IFOCUS_SUPER_ADMIN";
export const PROJECT_MANAGER = "PROJECT_MANAGER";
export const PASSWORD = "P@ssw0rd@123";
export const COORDINATOR = "COORDINATOR";
export const adminSuperAdmin = "adminSuperAdmin";
export const adminSuperAdminCustomerManger = "adminSuperAdminCustomerManger";
export const customerManagerProjectManager = "customerManagerProjectManager";
export const adminCoordinator = "adminCoordinator";
export const superAdminCustomerManger = "superAdminCustomerManger";
export const deleteValidate = (pocName) => {
  return (pocName?.length!==0 && pocName[0]["projectPocEmailAddress"] !== "")
};
export const deleteValidateForCSS = (cssAction) => {
  return (cssAction?.length <=0 || cssAction[0]["comment"] === "")
};

export const {role} = LocalStorage.getResponseData();
export const allowedRole=[IFOCUS_SUPER_ADMIN,CUSTOMER_MANAGER,PROJECT_MANAGER]
export const roleHandler = (roleData) => {
  switch (roleData) {
    case adminSuperAdmin:
      return role === IFOCUS_ADM || role === IFOCUS_SUPER_ADMIN;
    case adminSuperAdminCustomerManger:
      return (
        role === IFOCUS_ADM ||
        role === IFOCUS_SUPER_ADMIN ||
        role === CUSTOMER_MANAGER
      );
    case customerManagerProjectManager:
      return role === PROJECT_MANAGER || role === CUSTOMER_MANAGER;
    case adminCoordinator:
      return role === IFOCUS_ADM || role === COORDINATOR;
    case superAdminCustomerManger:
      return role === IFOCUS_SUPER_ADMIN || role === CUSTOMER_MANAGER;
    default:
      return role === IFOCUS_ADM;
  }
};
export const emptyValue = "Please fill current value first!";
export const lastValue = "Last Field Can't Deleted !";
export const apiError = "Error fetching question options";
export const fail = "Fail";
export const success = "Success";
export const meWatchReport = "MeWatch Report";
export const keyRelease = "Key Release";
export const viewDetails = "View details";
export const requiredField = "This field is required";
export const releasedDate = "released date";
export const invalidDate = "Invalid date format input: d/m/yyyy";
export const invalidColumn = "Invalid number of rows and columns";
export const invalidExcel="not a valid Excel file. check column name many be forward slash or empty cell"
export const serverError = "Something went wrong";
export const selectMonth = "Please select a Month.";
export const selectWeek = "Select Week.";
export const monthDataNotFound = "Selected month data not found";
export const weekDataNotFound="No data found for this week."
export const mobileNumberStartSingapore =
  "Mobile number cannot start with 9 in Singapore";
export const mobileNumberMustStartSingapore =
  "Mobile number must start with 7 or 8 in Singapore";
export const invalidPOCContactNumber = "Invalid POC contact number";
export const clientNameRequired = "Client name is required";
export const clientNameAlreadyExist = "Client name already exists";
export const locationRequired = "Location is required";
export const locationMust = "Location must contain only alphabets";
export const pocContactNumberRequired = "POC contact number is required";
export const invalidPOCEmail = "Invalid POC Email address";
export const pocEmailMust = "POC Email address is required";
export const pocEmailRequired = "POC Email address is required";
export const accountManagerRequired = "Account Manager is required";
export const accountmanagerMust = "Account manager must contain only alphabets";
export const remarkLength =
  "The maximum length of the Remarks field is 100 characters";
export const remarkRquired = "Remarks is required";
export const active = "ACTIVE";
export const inactive = "INACTIVE";
export const closedCap = "CLOSED";
export const projectTypeRequired = "Project Type is required";
export const fileUploadSuccess = "File uploaded successfully.";
export const submit = "Submit";
export const loading = "Loading...";
export const errorFetchData = "Error fetching data:";
export const weeklyReport = "Weekly Report";
export const dataNotFound = "Data Not Found!!";
export const tcExecuted = "TC'S EXECUTED/DRAFTED";
export const versionEnv = "VERSION/ENVIRONMENT";
export const meWatch = "MeWatch";
export const allWeeks = "All Weeks";
export const allPlatforms = "All Platforms";
export const viewMore = "View More..";
export const monthlyReports = "Monthly Reports";
export const insights = "Insights";
export const closed = "Closed";
export const inActive = "Inactive";
export const projects = "Projects";
export const selectMonthOption = "Select Month";
export const failedToFetch = "Failed to fetch data. Please try again later.";
export const csiSaved = "CSI Action saved successfully";
export const failedToSaved =
  "Failed to save CSI Action. Please try again later.";
export const csiUpdate = "CSI Action Updates successfully";
export const failedToUpdate =
  "Failed to Update CSI Action. Please try again later.";
export const startDate = "Start Date:";
export const endDate = "End Date:";
export const actionNotPerform = "Action Not perform yet";
export const thankYouFeedback = "Thank you for giving feedback!";
export const encounteredSubmitForm =
  "We encountered a problem while trying to submit your form. Please double-check your entries for any errors and try submitting again.";
export const css = "Customer Satisfaction Survey";
export const kindlyRate = "*Kindly rate our performance for all 7 attributes";
export const customerRep = "Customer representative";
export const from = "From";
export const to = "To";
export const fromDate = "from date";
export const toDate = "to date";
export const projectName = "Project name";
export const actionToImprove = "Actions to improve CSI/Sustain 100% CSI";
export const cancel = "Cancel";
export const submitSurvey = "Submit Survey";
export const save = "Save";
export const update = "Update";
export const successUpdate = "Successfully Updated";
export const successCreate = "Successfully Created";
export const errorSave = "Error saving data";
export const loginFailed = "Login failed";
export const usernameRequired = "Email or username is required";
export const invalidEmail = "Invalid email address";
export const loginAccount = "Log in to your account.";
export const forgotPassword = "Forgot Password?";
export const login = "Login";
export const dragAndDropFile =
  "Drag and drop an Excel file here, or click to select a file.";
export const jsonValue = "JSON Data:";
export const monthlyReportDownload =
  "Monthly Report Downloaded and Opened Successfully!!";
export const errorDownload = "Error downloading file";
export const customerManager = " Customer Manager Fetch Successful!!";
export const customerManagerNotFound = " Customer Manager Not Found!!!";
export const customerManagerError = "Error fetching Customer Manager";
export const projectManagerSuccess = " Project Manager Fetch Successful!!";
export const projectManagerNotFound = " Project Manager Not Found!!!";
export const projectManagerError = "Error fetching Project Manager";
export const projectNameRequired = "Project name is required";
export const projectNameExit = "Project name already exists";
export const projectPocRequired = "Project POC name is required";
export const projectPocMust = "Project POC name must contain only alphabets";
export const invalidProjectPocEmail = "Invalid Project POC email address ";
export const projectPocEmailRequired = "Project POC email address is required";
export const dateRequired = "Date is required";
export const projectTech = "Project Technologies is required";
export const dateFormat = "YYYY-MM-DD";
export const select = "Select";
export const uploadReport = "Upload Report";
export const initiated = "INITIATED";
export const unAuthCssFill = "You are not authorized to fill this survey";
export const surveyDetails = "Survey Details";
export const all = "All";
export const selectClient = "Select Client";
export const cssInitiated = "CSS Initiated Successfully";
export const cssInitiatedError = "Error initiating CSS";
export const cssInitiatedFail = "Fail initiating CSS";
export const userDetails = "Users Details";
export const registeredUsers = "Registered Users";
export const addUsers = "Add User +";
export const questionDetails = "Question Details";
export const addQuestion = "Add Question +";
export const questionAnswer = "Question Answer";
export const lastFourCss = "Recent Four Surveys";
export const recentSurveys = "Recent Surveys";
export const cssStatus = "CSS Status";
export const initiateSurvey = "Initiate Survey";
export const clientDetails = "Client Details";
export const addCient = "Add Client +";
export const noTickets = "No. of tickets";
export const noDefects = "No. of defects reported";
export const noCriticalBug = "No. of critical bugs";
export const platformStatistics = "Platform Statistics";
export const top = "top";
export const platform = "Platform";
export const count = "Count";
export const userNameRequired = "User Name is required";
export const userNameMust = "User Name must contain only alphabets";
export const invalidUserEmail = "Invalid User email address ";
export const userEmailRequired = "User email address is required";
export const notePass = `NOTE : By default Password is ${PASSWORD}`;
export const customerManagerName = "Customer Manager Name*";
export const roles = "Role*";
export const officalEmail = "Official Email*";
export const userName = "User name*";
export const questionRequired = "question name is required";
export const questionNameExits = "question name already exists";
export const isUnique = "is-unique";
export const remarks = "Remarks";
export const errorUploadFile = "Error uploading file";
export const uploadLetter = "Upload Letter";
export const downloadNewsletter = "Download newsletter";
export const passReset = "Password reset successfully!";
export const emailRequired = "Email is required";
export const currentPassRequired = "Current password is required";
export const passMust = "Password must be at least 8 characters";
export const passMustContain =
  "Password must contain at least one uppercase letter, one number, and one special character";
export const passMatch = "Passwords must match";
export const newPassMust = "New password is required";
export const passNotSame =
  "New password should not be the same as current password";
export const confirmPassRequired = "Confirm password is required";
export const newPass = "newPassword";
export const currentPass = "currentPassword";
export const resetPass = "Reset password";
export const passResetSuccess = "Password reset successfully";
export const confirmPass = "Confirm password*";
export const newPassStar = "New Passwords *";
export const currentPassStar = "Current password* ";
export const yourEmail = "Your email*";
export const home = "Home";
export const projectPocEmail = "Project POC Email address*";
export const projectPocName = "Project POC Name*";
export const projectDetails = "Project Details";
export const location = "Location*";
export const projectNameStar = "Project Name*";
export const projectTypeStar = "Project Type*";
export const otherType = "Other Type*";
export const customerManagerStar = "Customer Manager*";
export const projectStartDate = "Project Start Date*";
export const projectEndDate = "Project End Date*";
export const projectTechStar = "Project Technologies*";
export const projectStatus = "Project Status";
export const downloadNotPerform="Can't perform download operation data not found"
export const uploadPdfFile="Please upload a PDF file."
export const uploadExcelFile="Please upload a EXCEL file."
export const unExpectedError='An unexpected error occurred.'
export const startDateMustBeLesserThenEndDate = "Start date must be lesser then end date"
export const fileSizeExceed="File size exceeds 10 MB."
export const notFile="The provided input is not a File"
export const emptyDate="Date Column Empty"
export const friday="Friday"
export const removeOtherData="Remove other data from excel and reupload"
export const workerError="Worker Error:"
export const weeklyExcel="weeklyExcel"
export const releaseExcel="releaseExcel"
export const loadButton="Loading"
export const workerFuntion="/workerFunctions.js"
export const numberType="number"
export const stringType="string"
export const emptyCell='Empty Cell'
export const invalidFile="Invalid file format. Please select an Excel file."
export const noFileSelected="No file selected."
export const ALLOWED_EXTENSIONS = [".xlsx", ".xls"];
export const loginValdiation = {
  wrongCred: "Wrong credentials",
  sucessPass: "Password sent to email successfully",
  passResetFailed: "Password reset request failed.",
  passResetError: "Password reset request error:",
  passMustEight: "Password must be at least 8 characters",
  passRequired: "Password is required",
};
export const projectType = {
  development: "Development",
  testing: "Testing",
  support: "Support",
  consultancy: "Consultancy",
  others: "Others",
};
export const allowedMimeTypes = [
  "application/vnd.ms-excel", // .xls
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", // .xlsx
];
