import React,{useEffect,useRef, useState} from "react";
import { Link, useNavigate } from "react-router-dom";
import Header from "../Layout/Header/Header";
import Breadcrumb from "../Layout/Header/Breadcrumb";
import WeeklyGraphForCard from "../WeeklyReport/WeeklyGraphForCard";
import WeeklyTableForCard from "../WeeklyReport/WeeklyTableForCard";
import { DocumentChartBarIcon, TvIcon } from "@heroicons/react/24/outline";
import { useMyContext } from "../../context/ContextApi";
import { errorFetchData, insights, meWatchReport, monthlyReports, viewDetails, weeklyReport } from "../../constants/Roles";
import { projectName } from "../../utils/dateConverter";
import PDFViewer from "../MonthlyReport/PDFViewer";
import { toast } from "react-toastify";
import { apiGet } from "../../utils/apiHelper";

const Reports = () => {
  const { state } = useMyContext();
  const [lastMonthlyReport,setLastMonthlyReport]=useState(null);

  const breadcrumbs = [
    { name: "Home", href: "/dashboard", current: true },
    { name: "Clients", href: "/clients", current: true },
    {
      name: `${projectName||state?.navProjectName} Projects`,
      href: "/projects",
      current: true,
    },
    { name: `${projectName || state?.navProjectName} Reports`, href: "/report", current: false },
  ];
  const navigate = useNavigate();

  const handleTableClick = () => {
    navigate("/weeklytable");
  };

  const handleInsightClick = () => {
    navigate("/insights");
  };
  useEffect(() => {
    const getLastMonthlyReportPdf = async () => {
      try {
        const response = await apiGet(`/report/get-all`);
        if (response.data.length > 0) {
          const latestFile = response.data[response.data.length - 1];
          setLastMonthlyReport(latestFile.reportName);
        }
      } catch (error) {
        toast.error(errorFetchData);
      }
    };

    getLastMonthlyReportPdf();
  }, []);
  const PdfView = useRef(null);
  return (
    <div className="bg-gray-900 min-h-screen">
      <Header />
      <div className="bg-gray-900 mt-2 items-center justify-center">
        <div className="mx-auto  px-4 py-16 sm:px-6 sm:py-6  lg:px-8 custom-bg-color">
          <Breadcrumb items={breadcrumbs} />

          <div className="flex  flex-col sm:flex-row items-center justify-between  mt-6">
            <h3 className="ml-6 mt-4 sm:mt-0 text-xl font-medium leading-6 text-white ">
              {meWatchReport}
            </h3>
          </div>

          <div className="px-10 py-10">
            <div className="grid grid-rows-2 gap-10">
              <div className="grid grid-cols-2 gap-9 ">
                <div
                  className=" text-field-content border border-[#1E2B43] rounded-2xl"
                  onClick={handleTableClick}
                >
                  <div className="grid grid-cols-9">
                    <div className="col-span-4 p-6 pr-0 ">
                      <div className="flex space-x-2">
                      <TvIcon className=" w-8 h-8 custom-blue-text"/>
                        <span className="text-[#C8DBFF] text-2xl ]">
                         {weeklyReport}
                        </span>
                      </div>
                      <p className=" text-[#6B778E] mt-5">
                      Check the Weekly <br /> report of the project.
                      </p>

                      <button className=" text-[#A4B8DD] border mt-44 border-[#2C4063] px-5 py-2 rounded-full ">
                        {viewDetails}
                      </button>
                    </div>
                    <div className="col-span-5 mt-20">
                      <WeeklyTableForCard />
                    </div>
                  </div>
                </div>
                <div className=" text-field-content border border-[#1E2B43] rounded-2xl ">
                  <div className="grid grid-cols-9">
                    <div className="col-span-3 p-6 pr-0 ">
                      <div className="flex space-x-2">
                      <TvIcon className=" w-8 h-8 custom-blue-text"/>
                        <span className="text-[#C8DBFF] text-2xl ] text-nowrap">
                         {monthlyReports}
                        </span>
                      </div>
                      <p className=" text-[#6B778E] mt-5">
                        Check the Monthly <br /> report of the project.
                      </p>
                      <Link to="/monthly-report">
                        <button className=" text-[#A4B8DD] border mt-44 border-[#2C4063] px-5 py-2 rounded-full ">
                          {viewDetails}
                        </button>
                      </Link>
                    </div>
                    <div className="col-span-6 m-4">
                    <PDFViewer uploadedFileView={lastMonthlyReport} ref={PdfView} />
                    </div>
                  </div>
                </div>
              </div>

              <div className="grid grid-cols-1 gap-8">
                <div
                  className=" text-field-content border border-[#1E2B43] rounded-2xl"
                  onClick={handleInsightClick}
                >
                  <div className="grid grid-cols-9">
                    <div className="col-span-4 p-6 pr-0 ">
                      <div className="flex space-x-2">
                      <DocumentChartBarIcon className=" w-8 h-8 custom-blue-text"/>
                        <span className="text-[#C8DBFF] text-2xl ]">
                          {insights}
                        </span>
                      </div>
                      <p className=" text-[#6B778E] mt-5">
                        Check the insights for the <br /> Weekly reports .
                      </p>

                      <button className=" text-[#A4B8DD] border mt-44 border-[#2C4063] px-5 py-2 rounded-full ">
                        {viewDetails}
                      </button>
                    </div>
                    <div className="w-full  lg:ml-[-25%]">
                      {" "}
                      <WeeklyGraphForCard />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Reports;
