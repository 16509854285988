// ProjectModel.js
import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import {
  CUSTOMER_MANAGER,
  IFOCUS_ADM,
  IFOCUS_SUPER_ADMIN,
  PASSWORD,
  PROJECT_MANAGER,
  role,
} from "../../constants/Roles";
import { apiPost, apiPut, apiGet } from "../../utils/apiHelper";
import LocalStorage from "../StorageUtil/LocalStorage";
import { userModelMessage } from "../../constants/Validation";
const UserModel = ({ onClose, initialValues, user, onSubmit }) => {
  const [customerManagerId, setCustomerManagerId] = useState("");
  const [clients, setClients] = useState([]);
  const { clientId } = LocalStorage.getResponseData();
  const [client_id, setClientId] = useState(clientId);

  const roleManegment = (role) => {
    switch (role) {
      case PROJECT_MANAGER:
        return 3;
      case CUSTOMER_MANAGER:
        return 1;
      case IFOCUS_ADM:
        return 0;
      case IFOCUS_SUPER_ADMIN:
        return 2;
      default:
        return -1;
    }
  };

  const validationSchema = Yup.lazy((values) =>
    Yup.object().shape({
      name: Yup.string()
        .required(userModelMessage.message.userNameRequired)
        .matches(/^[a-zA-Z\s]+$/, userModelMessage.message.userNameMust),
      officialEmail: Yup.string()
        .email(userModelMessage.message.invalidUserEmail)
        .required(userModelMessage.message.userEmailRequired)
        .matches(
          /@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
          userModelMessage.message.invalidUserEmail
        ),
    })
  );
  const handleSave = (values) => {
    values.customerManagerId = customerManagerId;
    values.password = values.password ? values.password : PASSWORD;
    values.clientId = client_id;
    values.role = roleManegment(values.role);
    handleSaveProject(values);
  };
  useEffect(() => {
    fetchClients();
  }, []);

  const fetchClients = async () => {
    try {
      const clients = await apiGet(userModelMessage.api.clients);
      if (clients.status === 200) {
        setClients(clients.data);
      }
    } catch (error) {
      toast.error(userModelMessage.message.error);
    }
  };

  const handleSaveProject = async (values) => {
    onClose();
    try {
      if (JSON.stringify(initialValues) !== "{}") {
        await apiPut(userModelMessage.api.userUpdate+`${values.id}`, values);
        toast.success(userModelMessage.message.successUpdate);
      } else {
        await apiPost(userModelMessage.api.register, values);
        toast.success(userModelMessage.message.successCreate);
      }
    } catch (error) {
      toast.error(userModelMessage.message.errorSave);
    }
  };

  return (
    <div className="text-[#D2D2D2] fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50 overflow-auto">
      <div className="modal-content w-full md:max-w-[80%] lg:max-w-[50%] rounded-lg p-8 transform transition-all duration-300 ease-in-out opacity-100 animate-slide-down">
        <div className=" flex mb-4 mt-20 justify-between">
          <h2 className="text-2xl font-semibold">
            {userModelMessage.message.userDetails}
          </h2>
          {JSON.stringify(initialValues) === "{}" && (
            <h4 className="text-lg text-red-600 font-semibold mr-40">
              {userModelMessage.message.notePass}
            </h4>
          )}
        </div>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
        >
          {({ values, setFieldValue, validateForm }) => (
            <Form className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div>
                <label htmlFor="name" className="block custom-blue-text mb-1">
                  {userModelMessage.message.userName}
                </label>
                <Field
                  type="text"
                  id="name"
                  name="name"
                  className="w-full border border-[#1D2A42]  rounded-md px-3 py-2 bg-transparent"
                />
                <ErrorMessage
                  name="name"
                  component="div"
                  className="text-red-500 text-sm mt-1"
                />
              </div>
              <div>
                <label
                  htmlFor="officialEmail"
                  className="block custom-blue-text mb-1"
                >
                  {userModelMessage.message.officalEmail}
                </label>
                <Field
                  type="text"
                  id="officialEmail"
                  name="officialEmail"
                  className="w-full border border-[#1D2A42]  rounded-md px-3 py-2 bg-transparent"
                />
                <ErrorMessage
                  name="officialEmail"
                  component="div"
                  className="text-red-500 text-sm mt-1"
                />
              </div>

              <div>
                <label htmlFor="role" className="block custom-blue-text mb-1">
                  {userModelMessage.message.roles}
                </label>
                <Field
                  as="select"
                  id="role"
                  name="role"
                  className="w-full border border-[#1D2A42] rounded-md px-3 py-2 bg-transparent"
                >
                  <option className="dropdown-content" value="">
                    {userModelMessage.message.select}
                  </option>
                  <option className="dropdown-content" value={IFOCUS_ADM}>
                    {IFOCUS_ADM}
                  </option>
                  <option className="dropdown-content" value={CUSTOMER_MANAGER}>
                    {CUSTOMER_MANAGER}
                  </option>
                  <option
                    className="dropdown-content"
                    value={IFOCUS_SUPER_ADMIN}
                  >
                    {IFOCUS_SUPER_ADMIN}
                  </option>
                  <option className="dropdown-content" value={PROJECT_MANAGER}>
                    {PROJECT_MANAGER}
                  </option>
                </Field>
                <ErrorMessage
                  name="role"
                  component="div"
                  className="text-red-500 text-sm mt-1"
                />
              </div>
              {values.role === PROJECT_MANAGER && (
                <div>
                  <label className="block custom-blue-text mb-1">
                    {userModelMessage.message.customerManagerName}
                  </label>
                  <select
                    onChange={(e) => setCustomerManagerId(e.target.value)}
                    className="w-full border border-[#1D2A42] rounded-md px-3 py-2 bg-transparent"
                  >
                    <option value="">{userModelMessage.message.select}</option>
                    {user
                      .filter(({ role }) => role === CUSTOMER_MANAGER)
                      .map(({ id, name }) => (
                        <option key={id} value={id}>
                          {name}
                        </option>
                      ))}
                  </select>
                </div>
              )}
              {role === IFOCUS_SUPER_ADMIN && (
                <div>
                  <label
                    htmlFor="clientId"
                    className="block custom-blue-text mb-1"
                  >
                    {userModelMessage.message.clientId}
                  </label>
                  <select
                    className="w-full border border-[#1D2A42] rounded-md px-3 py-2 bg-transparent"
                    onChange={(e) => setClientId(e.target.value)}
                    required
                  >
                    <option value="">{userModelMessage.message.select}</option>
                    {clients?.map((name) => (
                      <option key={name.clientName} value={name.clientId}>
                        {name.clientName}
                      </option>
                    ))}
                  </select>
                </div>
              )}
              <div className="md:col-span-2 flex gap-2 justify-end">
                <button
                  type="button"
                  className="text-white px-3 py-1 rounded-sm w-40 h-11"
                  onClick={onClose}
                >
                  {userModelMessage.message.cancel}
                </button>
                <button
                  type="button"
                  onClick={() => handleSave(values)}
                  className="custom-btn-color text-white px-3 py-1 rounded-sm w-40 h-11"
                >
                  {userModelMessage.message.save}
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default UserModel;
